import React, { memo } from 'react';
import { StyleSheet } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { DataTable, Modal, Portal } from 'react-native-paper';
import { ezTheme } from '../../core/theme';
import TrackingShipmentResult from './TrackingShipmentResult';
import { Text } from '@ui-kitten/components';

const BatchTrackingShipmentResultModal = ({ data, onClose, visible }) => {
  return (
    <Portal>
      <Modal
        style={styles.container}
        visible={visible}
        onDismiss={onClose}
        dismissable={true}
        contentContainerStyle={styles.modalContent}>
        <React.Fragment>
          <DataTable.Header style={{ marginRight: 32 }}>
            <DataTable.Title>
              <Text category="s1">Shipment Order ID</Text>
            </DataTable.Title>
            <DataTable.Title>
              <Text category="s1">Tracking Number</Text>
            </DataTable.Title>
            <DataTable.Title>
              <Text category="s1">Courier</Text>
            </DataTable.Title>
          </DataTable.Header>
          <ScrollView style={{ height: '100%', width: '100%' }}>
            {(data || []).map((item) => {
              return <TrackingShipmentResult data={item} />;
            })}
          </ScrollView>
        </React.Fragment>
      </Modal>
    </Portal>
  );
};

const styles = StyleSheet.create({
  container: {},
  backdropStyle: {
    backgroundColor: ezTheme.backdropModalColor,
  },
  modalContent: {
    backgroundColor: 'white',
    padding: 20,
    width: '80%',
    height: '90%',
    borderRadius: 5,
    marginHorizontal: 'auto',
  },
});

export default memo(BatchTrackingShipmentResultModal);
