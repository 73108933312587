import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { Button, Icon, Input, Text } from '@ui-kitten/components';
import { useEffect, useState } from 'react';
import { View } from 'react-native';
import React from 'react';
import Table from './Table';
import { Card } from 'react-native-paper';
import DateRangeInput from './DateRangeInput';
import { format } from 'date-fns';
import { UIStatusWrapper } from './ui-status';

const TASK_RUNNER_INFO = gql`
  query taskRunnerInfo($payload: TaskRunnerInfoInput) {
    taskRunnerInfo(payload: $payload) {
      data {
        endTime
        taskName
      }
      total
    }
  }
`;
export const TaskRunnerInfo = ({ organisationId }) => {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [searchText, setSearchText] = useState('');
  const [load, { data, loading, error }] = useLazyQuery(TASK_RUNNER_INFO, {
    fetchPolicy: 'no-cache',
  });

  const resetFilter = async () => {
    setPageIndex(0);
    setPageSize(20);
    setSearchText('');
    await load({
      variables: {
        payload: {
          organisationId,
          pageSize: 20,
          pageIndex: 0,
          searchText: '',
        },
      },
    });
  };

  const LoadData = async () => {
    const result = await load({
      variables: {
        payload: {
          organisationId,
          pageSize,
          pageIndex,
          searchText,
        },
      },
    });
  };

  useEffect(() => {
    LoadData();
  }, []);

  return (
    <View>
      <Card.Title title="Data Synchronisation" />
      <View style={{ flexDirection: 'row', alignItems: 'flex-end', padding: 10 }}>
        <Input
          value={searchText}
          onChangeText={setSearchText}
          onSubmitEditing={LoadData}
          placeholder="Enter search key"
          accessoryLeft={(props) => <Icon {...props} name={'search'} />}
        />
        <Button style={{ marginHorizontal: 10 }} onPress={LoadData}>
          Search
        </Button>
        <Button onPress={resetFilter} appearance="ghost" status="danger">
          Reset Filter
        </Button>
      </View>
      <UIStatusWrapper
        status={{
          error: error,
          indeterminate: loading,
        }}>
        <Table
          heightOffset={400}
          items={data?.taskRunnerInfo?.data || []}
          getId={(item) => item.id}
          displayKeys={['taskName', 'endTime']}
          titleByKey={{ taskName: 'Task Name', endTime: 'Time' }}
          currentPage={pageIndex}
          onPageChange={(index) => {
            setPageIndex(index);
          }}
          totalItemNum={data?.taskRunnerInfo?.total || 0}
          itemsPerPage={pageSize}
          setPageSize={(size) => {
            setPageSize(size);
          }}
        />
      </UIStatusWrapper>
    </View>
  );
};
