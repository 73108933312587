import React, { memo, useState, useCallback, useEffect } from 'react';
import { StyleSheet } from 'react-native';
import { Chip, Card } from 'react-native-paper';
import TextInput from './input/TextInput';
import debounce from 'lodash.debounce';
import { useIsMobile } from 'src/core/responsive.utils';
import {
  Layout,
  Button,
  Icon,
  Popover,
  Text,
  CheckBox,
  RangeDatepicker,
} from '@ui-kitten/components';
import { ScrollView } from 'react-native-gesture-handler';
import { subYears } from 'date-fns';

const DEBOUNCE_DELAY_MS = 800;

const TransactionType = {
  open: 'open',
  recharge: 'recharge',
  refund: 'refund',
  charge: 'charge',
};

const MAX_RANGE_DATE_PICKER = new Date();
const MIN_RANGE_DATE_PICKER = subYears(MAX_RANGE_DATE_PICKER, 3);

const TransactionControl = ({
  filterParams = {},
  indicators = [],
  onSearchTermChange = () => {},
  onFilterChange = () => {},
  onFilterClear = () => {},
}) => {
  const isMobileDevice = useIsMobile();
  const [searchTerm, setSearchTerm] = useState('');
  useEffect(() => {
    setSearchTerm(filterParams.searchTerm);
  }, [filterParams.searchTerm]);

  const [statusDropdownVisible, setStatusDropdownVisible] = useState(false);
  const _onChangeText = useCallback(
    debounce((text) => {
      const s = text?.trim();
      if (s === '') {
        onSearchTermChange(null);
      } else if (s?.length >= 3) {
        onSearchTermChange(s);
      }
    }, DEBOUNCE_DELAY_MS),
    [],
  );

  return (
    <Card visible={true} style={styles.bannerContainer} actions={[]}>
      <Layout style={isMobileDevice ? styles.mobileBanner : styles.desktopBanner}>
        <TextInput
          autoFocus
          value={searchTerm}
          onChangeText={(text) => {
            setSearchTerm(text);
            _onChangeText(text);
          }}
          placeholder="Search transaction"
          size="medium"
          style={isMobileDevice ? styles.mobileSearchBar : styles.desktopSearchBar}
        />

        <RangeDatepicker
          placeholder="From - To"
          range={(filterParams && filterParams.range) || {}}
          onSelect={(nextRange) => onFilterChange({ range: nextRange })}
          min={MIN_RANGE_DATE_PICKER}
          max={MAX_RANGE_DATE_PICKER}
        />

        <Layout style={isMobileDevice ? styles.mobileButtonGroup : styles.desktopButtonGroup}>
          <Popover
            visible={statusDropdownVisible}
            anchor={() => (
              <Button
                status="primary"
                appearance="ghost"
                style={[styles.button]}
                onPress={() => setStatusDropdownVisible(true)}
                accessoryRight={statusDropdownVisible ? ArrowUpIcon : ArrowDownIcon}>
                Type
              </Button>
            )}
            onBackdropPress={() => {
              setStatusDropdownVisible(false);
            }}
            style={styles.popover}>
            <Layout style={styles.popover}>
              <Text>Select type</Text>
              <ScrollView style={{ maxHeight: 250 }}>
                {Object.values(TransactionType).map((key) => (
                  <CheckBox
                    key={`${key}`}
                    checked={filterParams.type === key}
                    onChange={(checked) => {
                      if (checked) {
                        onFilterChange({
                          type: key,
                        });
                      } else {
                        onFilterChange({
                          type: undefined,
                        });
                      }
                    }}
                    style={styles.checkItem}>
                    {key}
                  </CheckBox>
                ))}
              </ScrollView>
            </Layout>
          </Popover>

          <Button
            status="danger"
            appearance="ghost"
            onPress={() => {
              setSearchTerm('');
              onFilterClear();
            }}
            style={styles.button}>
            Reset filter
          </Button>
        </Layout>
      </Layout>
      <Layout style={styles.indicator}>
        {indicators.map((indicator, index) => (
          <Chip style={styles.chip} key={`${indicator}-${index}`}>
            {indicator}
          </Chip>
        ))}
      </Layout>
    </Card>
  );
};

const styles = StyleSheet.create({
  bannerContainer: {
    marginBottom: '0.5em',
    padding: '1em',
  },
  desktopBanner: {
    maxWidth: '1800px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  mobileBanner: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  indicator: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  chip: {
    marginTop: '0.4em',
    marginHorizontal: '0.2em',
    opacity: '0.5',
  },
  popover: {
    margin: '0.5em',
    padding: '0.5em',
  },
  rangeInput: {
    width: '100px',
    margin: '0.5em',
  },
  desktopButtonGroup: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  mobileButtonGroup: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  desktopSearchBar: {
    width: '45%',
    marginRight: '0.5em',
  },
  mobileSearchBar: {
    width: '100%',
  },
  button: {
    height: '45px',
  },
  icon: {
    width: '28px',
    height: '28px',
    stroke: 'white',
    strokeWidth: '0.8',
  },
  popover: {
    margin: '0.5em',
    padding: '0.5em',
  },
  checkItem: {
    marginVertical: 4,
    marginHorizontal: 12,
  },
});

const ArrowDownIcon = (props) => (
  <Icon
    {...props}
    name="arrow-ios-downward-outline"
    style={[props.style, styles.icon, { width: '20px', height: '20px' }]}
  />
);

const ArrowUpIcon = (props) => (
  <Icon
    {...props}
    name="arrow-ios-upward-outline"
    style={[props.style, styles.icon, { width: '20px', height: '20px' }]}
  />
);

export default memo(TransactionControl);
