import React, { useMemo } from 'react';
import { Card, Layout, ListItem, ModalService, Text } from '@ui-kitten/components';
import CreateOutboundForm from 'src/components/CreateOutboundForm';
import { mergeDeepWith } from 'ramda';
import OrderSummary from './OrderSummary';
import { observer } from 'mobx-react';
import { View, useWindowDimensions } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { paperNativeTheme } from 'src/core/theme';
import {
  countryStateCityCurrencyStore,
  outboundOrderStore,
  storeStore,
  warehouseAccountStore,
} from '../store';
import { MergeOrderDispatchStore } from '../store/MergeOrderDispatchStore';
import { UIStatusWrapper } from './ui-status';
import { createInitOutboundFormValues } from './CreateOutboundForm';
import { OrderTitle } from './OrderTitle';
import { volumeCmToMetre, weightGramToKg } from '@ezom/library/lib/cjs/utils';
import { ezTheme } from '../core/theme';

export default observer(
  ({ orderIds, warehouseAccountId, onDismiss, onSubmit, checkedItems, setCheckedItems }) => {
    const windowHeight = useWindowDimensions().height;
    const mergeOrderdispatchStore = useMemo(
      () =>
        new MergeOrderDispatchStore(
          outboundOrderStore.client,
          outboundOrderStore,
          storeStore,
          orderIds.map((id) => id.orderId),
          orderIds.map((id) => id.storeId),
          warehouseAccountStore,
          countryStateCityCurrencyStore,
        ),
      [orderIds, outboundOrderStore, storeStore],
    );

    let modalID = '';

    const showModal = (order) => {
      const contentElement = renderModalContentElement(order);
      modalID = ModalService.show(contentElement, {
        onBackdropPress: hideModal,
        backdropStyle: {
          backgroundColor: ezTheme.backdropModalColor,
        },
      });
    };

    const hideModal = () => {
      ModalService.hide(modalID);
    };

    const renderModalContentElement = (order) => {
      return (
        <View
          style={{
            width: 'fit-content',
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            maxHeight: '90%',
          }}>
          <OrderSummary order={order} onClose={hideModal} />
        </View>
      );
    };

    return (
      <View
        style={{
          display: 'flex',
          justifyContent: 'left',
          flexFlow: 'row',
          maxHeight: windowHeight - 70,
        }}>
        <UIStatusWrapper
          status={{
            error: mergeOrderdispatchStore.error,
            indeterminate:
              mergeOrderdispatchStore.loadingOrders ||
              mergeOrderdispatchStore.loadingInventories ||
              mergeOrderdispatchStore.loadingSkuMappings,
          }}>
          <ScrollView
            style={{
              flex: 1,
            }}>
            <View
              style={{
                borderRadius: '4px',
                borderColor: paperNativeTheme.colors.secondary,
                borderWidth: '0.5px',
                marginHorizontal: '0.8em',
              }}>
              <Title
                skuQuantity={mergeOrderdispatchStore.mergedSkuQuantity}
                outOfStockWarehouseSkus={mergeOrderdispatchStore.mergedOutOfStockWarehouseSkus}
                unmappedStoreSkus={mergeOrderdispatchStore.mergedUnmappedStoreSkus}
                totalWeight={mergeOrderdispatchStore.mergedTotalWeight}
                totalVolume={mergeOrderdispatchStore.mergedTotalVolume}
              />
              {mergeOrderdispatchStore.orders.map((order, index) => {
                return (
                  <ListItem key={order.id}>
                    <Card style={{ width: '100%' }} onPress={() => showModal(order)}>
                      <OrderTitle
                        orderNumber={order.displayId}
                        skuQuantity={mergeOrderdispatchStore.skuQuantity[index]}
                        outOfStockWarehouseSkus={
                          mergeOrderdispatchStore.outOfStockWarehouseSkus[index]
                        }
                        unmappedStoreSkus={mergeOrderdispatchStore.unmappedStoreSkus[index]}
                        totalWeight={mergeOrderdispatchStore.totalWeight[index]}
                        totalVolume={mergeOrderdispatchStore.totalVolume[index]}
                        order={mergeOrderdispatchStore.orders[index]}
                        checked={checkedItems.includes(order.id)}
                        onCheckedChange={() =>
                          setCheckedItems((prev) =>
                            prev.includes(order.id)
                              ? prev.filter((id) => id !== order.id)
                              : [...prev, order.id],
                          )
                        }
                      />
                    </Card>
                  </ListItem>
                );
              })}
            </View>
          </ScrollView>
        </UIStatusWrapper>
        <View
          style={{
            flex: 1,
          }}>
          <CreateOutboundForm
            onDismiss={onDismiss}
            onSubmit={onSubmit}
            warehouseAccountId={warehouseAccountId}
            initialConsignment={mergeOrderdispatchStore.mergedOutboundConsignment}
          />
        </View>
      </View>
    );
  },
);

export const Title = observer(
  ({ outOfStockWarehouseSkus, skuQuantity, unmappedStoreSkus, totalVolume, totalWeight }) => {
    return (
      <Layout style={{ minWidth: 650, padding: '0.8em' }}>
        <Layout style={{ flexDirection: 'row', marginTop: '0.4em' }}>
          <Text status="primary" style={{ marginBottom: '0.2em', width: '40%' }}>
            Total weight: {weightGramToKg(true)(totalWeight)}
          </Text>

          <Text status="primary" style={{ marginBottom: '0.2em', width: '40%' }}>
            Total volume: {volumeCmToMetre(true)(totalVolume)}
          </Text>
        </Layout>
        {unmappedStoreSkus.length > 0 ? (
          <Layout style={{ flexDirection: 'row', marginTop: '0.4em' }}>
            <Text category="label" status="warning" style={{ marginRight: '0.4em' }}>
              Store SKU not mapped:{' '}
            </Text>
            <Text
              status="basic"
              category="p2"
              style={{ marginRight: '0.4em', maxWidth: 'calc(100% - 160px)' }}>
              {unmappedStoreSkus.join(',  ')}
            </Text>
          </Layout>
        ) : null}
        {skuQuantity.length === 0 ? (
          <Text category="label" status="danger" style={{ marginTop: '0.4em' }}>
            No shippable warehouse SKU
          </Text>
        ) : null}
        {outOfStockWarehouseSkus.length > 0 ? (
          <Layout style={{ flexDirection: 'row', marginTop: '0.4em' }}>
            <Text category="label" status="danger" style={{ marginRight: '0.4em' }}>
              Out of stock:{' '}
            </Text>
            <Text
              status="basic"
              category="p2"
              style={{ marginRight: '0.4em', maxWidth: 'calc(100% - 160px)' }}>
              {outOfStockWarehouseSkus.join(',  ')}
            </Text>
          </Layout>
        ) : null}
      </Layout>
    );
  },
);
