import * as React from 'react';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { useIsMobile } from '../core/responsive.utils';
import {
  DashboardScreen,
  OrderDetailScreen,
  OrderListScreen,
  UserAccountBalanceScreen,
  OrgProfileScreen,
} from './index';
import OrderDispatchScreen from './order/OrderDispatchScreen';
import NavigationHeaderRight from '../components/NavigationHeaderRight';
import { SecondaryTopBar } from '../components/SecondaryTopBar';
import { paperNativeTheme } from 'src/core/theme';
import InventoryAnalytics from './analytics/InventoryAnalytics';
import SalesAnalytics from './analytics/SalesAnalytics';
import SkuList from './inventory/SkuList';
import InboundList from './inventory/InboundList';
import InboundDetail from './inventory/InboundDetail';
import OutboundList from './inventory/OutboundList';
import OutboundDetail from './inventory/OutboundDetail';
import ShipmentOrderDetail from './shipments/ShipmentOrderDetail';
import SkuDetail from './inventory/SkuDetail';
import {
  Drawer as UIKittenDrawer,
  DrawerItem,
  DrawerGroup,
  IndexPath,
} from '@ui-kitten/components';
import { IconButton } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';
import SkuMappingList from './inventory/SkuMappingList';
import FulfillmentTable from '../components/FulfillmentTable';
import { AddStoreScreen } from './AddStoreScreen';
import RestockList from './inventory/RestockList';
import { OrgListScreen } from './account-manager/OrgListScreen';
import TransferList from './inventory/TransferList';
import TransferDetail from './inventory/TransferDetail';
import CourierShipmentList from './shipments/CourierShipmentList';
import PickingTaskList from './inventory/PickingTaskList';
import PickingTaskDetail from './inventory/PickingTaskDetail';
import {
  playSuccessSoundAsync,
  useIsAccountManager,
  useIsEzomUser,
  useIsWmsManager,
  useOnBarcodeScanned,
} from '../core/utils/utils';
import { observer } from 'mobx-react';
import { isPickingTaskId } from '@ezom/library/lib/cjs/id';
import { pickingTaskTableStore, warehouseAccountStore } from '../store';
import PackagingMaterialList from './inventory/PackagingMaterialList';
import OcPackingScreen from './inventory/OcPackingScreen';
import { EditWmsAccountScreen } from './auth/EditWmsAccountScreen';
import NewShipment from './shipments/NewShipment';
import AddressBookList from './shipments/AddressBookList';
import { View } from 'react-native';

export const ScreenWrapper = ({ children }) => {
  return (
    <View style={{ flex: 1 }}>
      <SecondaryTopBar />
      {children}
    </View>
  );
};

const Drawer = createDrawerNavigator();

const getActiveRouteName = (route) => {
  if (!route) return undefined;

  if (route.state) {
    return getActiveRouteName((route.state.routes || [])[route.state.index]);
  }

  return route.name;
};

const routeNameToSelectedIndex = (routeName) => {
  switch (routeName) {
    case 'DashboardScreen':
      return new IndexPath(0);
    case 'OrderListScreen':
      return new IndexPath(1);
    case 'FulfillTable':
      return new IndexPath(0, 1);
    case 'Warehouse':
      return new IndexPath(0, 2);
    case 'InboundConsignments':
      return new IndexPath(1, 2);
    case 'OutboundConsignments':
      return new IndexPath(2, 2);
    case 'TransferConsignments':
      return new IndexPath(3, 2);
    case 'SkuMappingList':
      return new IndexPath(4, 2);
    case 'RestockList':
      return new IndexPath(5, 2);
    case 'PickingTaskList':
      return new IndexPath(6, 2);
    case 'InventoryAnalytics':
      return new IndexPath(0, 3);
    case 'SalesAnalytics':
      return new IndexPath(1, 3);
    case 'NewShipment':
      return new IndexPath(0, 4);
    case 'CourierShipmentList':
      return new IndexPath(1, 4);
    case 'AddressBook':
      return new IndexPath(2, 4);
  }
};

const CustomDrawerContent = (props) => {
  const navigation = useNavigation();
  const color = paperNativeTheme.colors.primary;

  const [selectedIndex, setSelectedIndex] = React.useState(new IndexPath(0));

  React.useEffect(() => {
    setSelectedIndex(routeNameToSelectedIndex(props.state.routeNames[props.state.index]));
  }, [props.state]);

  const isWmsManager = useIsWmsManager();
  const isEzomUser = useIsEzomUser();

  return (
    <UIKittenDrawer
      selectedIndex={selectedIndex}
      onSelect={(index) => {
        setSelectedIndex(index);
      }}>
      <DrawerItem
        title="Dashboard"
        accessoryLeft={(props) => <IconButton color={color} icon="view-dashboard" />}
        onPress={() => navigation.navigate('DashboardScreen')}
      />

      {isEzomUser ? (
        <DrawerGroup
          title="Orders"
          accessoryLeft={(props) => <IconButton color={color} icon="dolly" />}
          onPress={() => navigation.navigate('OrderListScreen')}>
          <DrawerItem title="Fulfill Orders" onPress={() => navigation.navigate('FulfillTable')} />
        </DrawerGroup>
      ) : (
        <></>
      )}

      {/*NOTE: it is not possible to open DrawerGroup by default at the moment. See this
       upstream ticket: https://github.com/akveo/react-native-ui-kitten/issues/1310*/}
      <DrawerGroup
        title="Warehouse"
        accessoryLeft={(props) => <IconButton color={color} icon="warehouse" />}>
        <DrawerItem title="Inventory" onPress={() => navigation.navigate('Inventory')} />
        <DrawerItem
          title="Inbound Consignments"
          onPress={() => navigation.navigate('InboundConsignments')}
        />
        <DrawerItem
          title="Outbound Consignments"
          onPress={() => navigation.navigate('OutboundConsignments')}
        />
        <DrawerItem
          title="Transfer Consignments"
          onPress={() => navigation.navigate('TransferConsignments')}
        />
        {isEzomUser ? (
          <DrawerItem title="SKU Mapping" onPress={() => navigation.navigate('SkuMappingList')} />
        ) : (
          <></>
        )}
        {isEzomUser ? (
          <DrawerItem title="Inventory health" onPress={() => navigation.navigate('RestockList')} />
        ) : (
          <></>
        )}
        {isWmsManager ? (
          <DrawerItem
            title="Picking Tasks"
            onPress={() => navigation.navigate('PickingTaskList')}
          />
        ) : (
          <></>
        )}
        {isWmsManager ? (
          <DrawerItem
            title="Packaging Material"
            onPress={() => navigation.navigate('PackagingMaterialList')}
          />
        ) : (
          <></>
        )}
      </DrawerGroup>
      <DrawerGroup
        title="Analytics"
        accessoryLeft={(props) => <IconButton color={color} icon="chart-bar-stacked" />}>
        <DrawerItem title="Inventory" onPress={() => navigation.navigate('InventoryAnalytics')} />
        {isEzomUser ? (
          <DrawerItem title="Sales" onPress={() => navigation.navigate('SalesAnalytics')} />
        ) : (
          <></>
        )}
      </DrawerGroup>
      {isEzomUser ? (
        <DrawerGroup
          title="Shipments"
          accessoryLeft={(props) => <IconButton color={color} icon="map" />}>
          <DrawerItem title="New shipment" onPress={() => navigation.navigate('NewShipment')} />
          <DrawerItem
            title="Courier shipments"
            onPress={() => navigation.navigate('CourierShipmentList')}
          />
          <DrawerItem title="Address book" onPress={() => navigation.navigate('AddressBook')} />
        </DrawerGroup>
      ) : (
        <></>
      )}
    </UIKittenDrawer>
  );
};

export default observer(({ user }) => {
  const isMobile = useIsMobile();
  const navigation = useNavigation();
  const isWmsManager = useIsWmsManager();
  const isAccountManager = useIsAccountManager();
  useOnBarcodeScanned(async (barcode) => {
    if (isWmsManager) {
      if (isPickingTaskId(barcode)) {
        const task = await pickingTaskTableStore.getPickingTask(barcode);
        if (task) {
          await playSuccessSoundAsync();
          navigation.navigate('PickingTaskDetail', {
            id: barcode,
            warehouseAccountId: warehouseAccountStore.warehouseAccountId,
          });
        } else {
          pickingTaskTableStore.setSearchTerm(barcode);
        }
      }
    }
  }, []);

  return (
    <Drawer.Navigator
      initialRouteName="DashboardScreen"
      screenOptions={{
        unmountOnBlur: true,
        headerShown: true,
        headerStyle: {
          backgroundColor: paperNativeTheme.colors.primary,
        },
        headerTintColor: '#fff',
        headerTitleStyle: {
          fontWeight: 'bold',
        },
        headerRight: (props) => (
          <NavigationHeaderRight isAccountManager={isAccountManager} user={user} />
        ),
      }}
      drawerType={isMobile ? 'front' : 'permanent'}
      drawerContent={(props) => <CustomDrawerContent {...props} user={user} />}
      drawerStyle={{ width: 'fit-content' }}>
      <Drawer.Screen
        name="OrgListScreen"
        component={OrgListScreen}
        options={{ title: 'Organisation list' }}
      />

      <Drawer.Screen
        name="DashboardScreen"
        component={DashboardScreen}
        options={{ title: 'Dashboard' }}
      />

      {/*********************** User account *********************/}
      <Drawer.Screen
        name="UserAccountBalanceScreen"
        component={(props) => <UserAccountBalanceScreen {...props} user={user} />}
        options={{ title: 'Your account balance' }}
      />

      <Drawer.Screen
        name="UserAccountBalanceScreenWithPaymentStatus"
        component={(props) => <UserAccountBalanceScreen {...props} user={user} />}
        options={{ title: 'Your account balance' }}
      />

      <Drawer.Screen
        name="OrgProfileScreen"
        component={OrgProfileScreen}
        options={{ title: 'Your organisation' }}
      />

      <Drawer.Screen
        name="AddStoreScreen"
        component={AddStoreScreen}
        options={{ title: 'Add store' }}
      />

      <Drawer.Screen
        name="EditWmsAccountScreen"
        component={EditWmsAccountScreen}
        options={{ title: 'Edit WMS Account' }}
      />

      {/*********************** Order *********************/}
      <Drawer.Screen
        name="OrderListScreen"
        component={OrderListScreen}
        options={{ title: 'Order list' }}
      />

      <Drawer.Screen
        name="OrderDetailScreen"
        // Use inline function to ensure the component is unmountd when leaving route (otherwise will be too many re-rendering)
        component={(props) => <OrderDetailScreen {...props} />}
        options={{ title: 'Order detail' }}
      />

      <Drawer.Screen
        name="OrderDispatchScreen"
        // Use inline function to ensure the component is unmountd when leaving route (otherwise will be too many re-rendering)
        component={(props) => <OrderDispatchScreen {...props} />}
        options={{ title: 'Order dispatch' }}
      />

      {/*********************** Inventory *********************/}
      <Drawer.Screen
        name="Inventory"
        component={(props) => (
          <ScreenWrapper>
            <SkuList {...props} />
          </ScreenWrapper>
        )}
      />
      <Drawer.Screen
        name="SkuMappingList"
        component={(props) => (
          <ScreenWrapper>
            <SkuMappingList {...props} />
          </ScreenWrapper>
        )}
        options={{ title: 'SKU mappings' }}
      />
      <Drawer.Screen name="SkuDetail" component={SkuDetail} options={{ title: 'SKU details' }} />
      <Drawer.Screen
        name="InboundConsignments"
        component={(props) => (
          <ScreenWrapper>
            <InboundList {...props} />
          </ScreenWrapper>
        )}
        options={{ title: 'Inbound consignments' }}
      />
      <Drawer.Screen
        name="InboundDetail"
        component={InboundDetail}
        options={{ title: 'Inbound consignment details' }}
      />
      <Drawer.Screen
        name="OutboundConsignments"
        component={(props) => (
          <ScreenWrapper>
            <OutboundList {...props} />
          </ScreenWrapper>
        )}
        options={{ title: 'Outbound consignments' }}
      />
      <Drawer.Screen
        name="OutboundDetailScreen"
        component={OutboundDetail}
        options={{ title: 'Outbound consignment details' }}
      />
      <Drawer.Screen
        name="TransferConsignments"
        component={TransferList}
        options={{ title: 'Transfer consignments' }}
      />
      <Drawer.Screen
        name="TransferDetail"
        component={TransferDetail}
        options={{ title: 'Transfer consignment details' }}
      />
      <Drawer.Screen
        name="FulfillTable"
        component={(props) => <FulfillmentTable {...props} />}
        options={{ title: 'Fulfillments' }}
      />
      <Drawer.Screen
        name="RestockList"
        component={RestockList}
        options={{ title: 'Inventory health' }}
      />
      <Drawer.Screen
        name="PickingTaskList"
        component={PickingTaskList}
        options={{ title: 'Picking tasks' }}
      />
      <Drawer.Screen
        name="PackagingMaterialList"
        component={(props) => (
          <ScreenWrapper>
            <PackagingMaterialList {...props} />
          </ScreenWrapper>
        )}
        options={{ title: 'Packaging material' }}
      />
      <Drawer.Screen
        name="PickingTaskDetail"
        component={PickingTaskDetail}
        options={{ title: 'Picking task details' }}
      />
      <Drawer.Screen
        name="OcPackingScreen"
        component={OcPackingScreen}
        options={{ title: 'OC packing' }}
      />
      {/*********************** Analytics *********************/}
      <Drawer.Screen
        name="InventoryAnalytics"
        component={InventoryAnalytics}
        options={{ title: 'Inventory analytics' }}
      />
      <Drawer.Screen
        name="SalesAnalytics"
        component={SalesAnalytics}
        options={{ title: 'Sales analytics' }}
      />
      {/*********************** Shipments *********************/}
      <Drawer.Screen
        name="NewShipment"
        component={(props) => (
          <ScreenWrapper>
            <NewShipment {...props} />
          </ScreenWrapper>
        )}
        options={{ title: 'New shipment' }}
      />
      <Drawer.Screen
        name="CourierShipmentList"
        component={(props) => (
          <ScreenWrapper>
            <CourierShipmentList {...props} />
          </ScreenWrapper>
        )}
        options={{ title: 'Courier shipments' }}
      />
      <Drawer.Screen
        name="ShipmentOrderDetail"
        component={ShipmentOrderDetail}
        options={{ title: 'Shipment order details' }}
      />
      <Drawer.Screen
        name="AddressBook"
        component={AddressBookList}
        options={{ title: 'Address book' }}
      />
    </Drawer.Navigator>
  );
});
