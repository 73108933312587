import gql from 'graphql-tag';
import { flow, makeAutoObservable } from 'mobx';
import {
  ORDER_FULFILLMENT_STATUSES,
  ORDER_STATUSES,
  ORDER_STOCKS_STATUS,
  SYNC_ORDERS,
} from '../core/utils/utils';
import { ORDER_FINANCIAL_STATUSES } from '@ezom/library/lib/cjs/constants';
import { omit, transpose, zipObj } from 'ramda';

const ORDER_PAGE = gql`
  query orderPage(
    $storeIds: [ID!]!
    $orderNumber: String
    $fulfillmentStatuses: [FulfillmentStatus!]
    $financialStatuses: [FinancialStatus!]
    $minCreated: String
    $maxCreated: String
    $customerEmail: String
    $customerName: String
    $sortDirection: String
    $sortByKey: String
    $searchTerm: String
    $countryCodes: [String!]!
    $offset: Int
    $limit: Int
    $statuses: [OrderStatus!]
    $warehouseAccountIds: [ID!]!
    $stockStatus: [StockStatus!]
  ) {
    orderPage(
      storeIds: $storeIds
      orderNumber: $orderNumber
      fulfillmentStatuses: $fulfillmentStatuses
      financialStatuses: $financialStatuses
      minCreated: $minCreated
      maxCreated: $maxCreated
      customerEmail: $customerEmail
      customerName: $customerName
      sortDirection: $sortDirection
      sortByKey: $sortByKey
      searchTerm: $searchTerm
      countryCodes: $countryCodes
      limit: $limit
      offset: $offset
      statuses: $statuses
      warehouseAccountIds: $warehouseAccountIds
      stockStatus: $stockStatus
    ) {
      limit
      offset
      total
      orders {
        id
        displayId
        number
        createdAt
        provider
        fulfillmentStatus
        financialStatus
        platformId
        shippingAddress {
          address1
          address2
          countryCode
          name
          province
          provinceCode
          city
          zip
        }
        lineItems {
          platformId
          storeId
          sku
          name
          variant
          variantId
          quantity
          fulfillableQuantity
        }
        storeId
        storeName
        price
        currency
        dontHaveAvailableAndOnwayStock
        dontHaveAvailableStock
      }
    }
  }
`;

const defaultParams = {
  fulfillmentStatuses: [
    ORDER_FULFILLMENT_STATUSES.unfulfilled,
    ORDER_FULFILLMENT_STATUSES.partial,
    ORDER_FULFILLMENT_STATUSES.in_progress,
  ],
  financialStatuses: [
    ORDER_FINANCIAL_STATUSES.paid,
    ORDER_FINANCIAL_STATUSES.partially_paid,
    ORDER_FINANCIAL_STATUSES.partially_refunded,
  ],
  providers: [],
  storeNames: [],
  orderNumber: '',
  statuses: [ORDER_STATUSES.open],
  countryCodes: [],
  stockStatus: [],
};

export class OrderTableStore {
  cachedItems = null;
  exportedItems = null; // Store items for exporting csv
  loadingOrders = true;
  error = null;
  filterParams = defaultParams;
  limit = 20;
  total = null;
  currentPage = 0;
  warehouseSelectionVisible = false;

  constructor(client, storeStore, salesStatisticsStore) {
    this.client = client;
    this.storeStore = storeStore;
    this.salesStatisticsStore = salesStatisticsStore;
    makeAutoObservable(
      this,
      {
        fetchItems: flow,
        deleteItems: flow,
        syncOrders: flow,
        client: false,
        storeStore: false,
        salesStatisticsStore: false,
      },
      { autoBind: true },
    );
  }

  get offset() {
    return this.currentPage * this.limit;
  }

  setLimit(limit) {
    this.limit = limit;
    this.cachedItems = null;
  }

  setCurrentPage(page) {
    this.currentPage = page;
    this.cachedItems = null;
  }

  setPageSize = (limit) => {
    this.limit = limit;
    this.cachedItems = null;
  };

  *syncOrders(storeId, forceAll) {
    try {
      this.loadingOrders = true;
      yield this.client.mutate({
        mutation: SYNC_ORDERS,
        variables: {
          storeId: storeId,
          forceAll: forceAll,
        },
        fetchPolicy: 'no-cache',
      });
    } catch (error) {
      this.error = error;
      console.error(error);
    } finally {
      this.loadingOrders = false;
    }
  }

  resetFilterParams() {
    this.filterParams = defaultParams;
    this.cachedItems = null;
  }

  setFilterParams(params) {
    this.filterParams = params;
    this.cachedItems = null;
    this.currentPage = 0;
  }

  get loading() {
    return this.loadingOrders || this.salesStatisticsStore.loadingStoreSkuStock;
  }

  get items() {
    if (!this.cachedItems) {
      this.fetchItems();
    }
    // fetch stockSkuStock
    this.salesStatisticsStore.storeSkuStock;
    return this.cachedItems || [];
  }

  *fetchItems(forExport = false) {
    try {
      this.loadingOrders = true;
      let stores = yield this.storeStore.getEnabledStores();
      const warehouseAccountIds =
        this.salesStatisticsStore.warehouseAccountStore.warehouseAccountIds;
      if (this.filterParams.providers && this.filterParams.providers.length) {
        const providerSet = new Set(this.filterParams.providers || []);
        stores = stores.filter((s) => providerSet.has(s.provider));
      }

      if (this.filterParams.storeNames && this.filterParams.storeNames.length) {
        const storeNameSet = new Set(this.filterParams.storeNames || []);
        stores = stores.filter((s) => storeNameSet.has(s.name));
      }

      let variables = {
        ...omit(['providers'], this.filterParams),
        storeIds: stores.map((s) => s.id),
        warehouseAccountIds,
      };

      if (!forExport) {
        variables.limit = this.limit;
        variables.offset = this.offset;
      }

      const {
        data: {
          orderPage: { orders, total },
        },
      } = yield this.client.query({
        query: ORDER_PAGE,
        variables,
        fetchPolicy: 'no-cache',
      });

      // Assigns the fetched item to either the `exportedItems` or `cachedItems` property
      // Depending on whether the data is being fetched for export or not.
      if (forExport) this.exportedItems = orders;
      else this.cachedItems = orders;

      this.total = total;
    } catch (error) {
      this.error = error;
      console.error(error);
    } finally {
      this.loadingOrders = false;
    }
  }

  *cancelItem(id) {
    // TODO
  }

  setOnWarehouseSelectionSubmit = (onSubmit) => {
    this.onWarehouseSelectionSubmit = onSubmit;
  };

  setWarehouseSelectionVisible = (visible) => {
    this.warehouseSelectionVisible = visible;
  };
}
