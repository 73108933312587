import React, { memo, useState } from 'react';
import { View } from 'react-native';
import { Button, DataTable, Divider, List } from 'react-native-paper';
import { downloadBase64Image } from '../../core/utils/utils';

const TrackingShipmentResult = ({ data }) => {
  const { trackingEvents, trackingNumber, error, id, shipmentOrderId, courier } = data;
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <List.Accordion
      style={{ padding: 0 }}
      title={
        <DataTable.Row
          style={{
            width: '100%',
            flex: 1,
            borderBottomWidth: 0,
          }}
          borderless>
          <DataTable.Cell style={{ flex: 1 }}>{shipmentOrderId}</DataTable.Cell>
          <DataTable.Cell style={{ flex: 1 }}>{trackingNumber}</DataTable.Cell>
          <DataTable.Cell style={{ flex: 1 }}>{courier}</DataTable.Cell>
        </DataTable.Row>
      }
      expanded={isExpanded}
      onPress={() => setIsExpanded(!isExpanded)}>
      {error && (
        <React.Fragment>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <List.Item title={error} titleStyle={{ color: 'red' }} />
            <View></View>
          </View>
        </React.Fragment>
      )}
      {!error &&
        (trackingEvents || []).map((item) => {
          return (
            <React.Fragment>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                <List.Item
                  title={item.status}
                  description={`${new Date(item.date).toLocaleDateString()} ${new Date(
                    item.date,
                  ).toLocaleTimeString()}`}
                />
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    marginRight: 10,
                  }}>
                  {item.pod && (
                    <Button
                      icon={'image'}
                      onPress={() => {
                        downloadBase64Image(item.pod, `POD_${trackingNumber}.jpg`);
                      }}>
                      POD
                    </Button>
                  )}
                  {item.signature && (
                    <Button
                      icon={'fountain-pen-tip'}
                      onPress={() => {
                        downloadBase64Image(item.signature, `Signature_${trackingNumber}.jpg`);
                      }}>
                      Signature
                    </Button>
                  )}
                </View>
              </View>
              <Divider />
            </React.Fragment>
          );
        })}
    </List.Accordion>
  );
};

export default memo(TrackingShipmentResult);
