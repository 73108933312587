import React from 'react';
import Spinner from 'src/components/Spinner';
import { Text } from '@ui-kitten/components';
import { View, StyleSheet } from 'react-native';
import { observer } from 'mobx-react';

const styles = StyleSheet.create({
  container: {
    minHeight: 100,
    flex: 1,
    padding: '1em',
  },
});

export const UIStatusWrapper = observer(
  ({ status, children, key, emptyDataMsg = 'No data found.', loadingDataMsg = 'Loading' }) => {
    let content = null;

    if (status.indeterminate) {
      content = <Spinner content={loadingDataMsg} />;
    } else if (status.error) {
      content = (
        <Text status="danger">
          {React.isValidElement(status.error)
            ? status.error
            : status.error instanceof Error
            ? status.error.message
            : JSON.stringify(status.error)}
        </Text>
      );
    } else if (status.empty) {
      content = <Text status="info">{emptyDataMsg}</Text>;
    }

    return content ? (
      <View style={[styles.container, { alignItems: 'center' }]} key={key}>
        {content}
      </View>
    ) : (
      children || <React.Fragment />
    );
  },
);
