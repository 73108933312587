import { Card, Button, Divider, Avatar, IconButton, Menu, Portal, Modal } from 'react-native-paper';
import React from 'react';
import { StyleSheet } from 'react-native';
import { Formik } from 'formik';
import { USER_SETTINGS } from 'src/core/user-settings';
import { isNil, omit } from 'ramda';
import { TextInputField } from 'src/components/input/TextInputField';
import { useIsMobile } from '../../core/responsive.utils';
import { gql, useMutation } from '@apollo/client';
import OrderCancelForm from '../../components/OrderCancelForm';
import { unifiedAlert } from '../../core/utils/utils';
import { STORE_PROVIDERS } from '@ezom/library/lib/cjs/constants';

const styles = StyleSheet.create({
  card: {
    marginRight: '0.4em',
  },

  desktopCard: {
    minWidth: '420px',
    width: '25%',
  },

  mobileCard: {
    marginBottom: '1em',
    width: '90%',
  },

  menuListIcons: {
    margin: 0,
    alignItems: 'start',
  },

  field: {
    minWidth: 380,
    marginVertical: 8,
  },
});

const UPDATE_ORDER = gql`
  mutation updateOrder($input: OrderInput!, $storeId: ID!) {
    updateOrder(input: $input, storeId: $storeId) {
      order {
        id
      }
      userErrors {
        field
        message
      }
    }
  }
`;

const TOGGLE_STATUS_FULFILLED = gql`
  mutation toggleStatusFulfilled(
    $id: ID!
    $storeId: ID!
    $currentStatus: String
    $newStatus: String
  ) {
    toggleStatusFulfilled(
      id: $id
      storeId: $storeId
      currentStatus: $currentStatus
      newStatus: $newStatus
    )
  }
`;

export default ({
  order,
  noTitle = false,
  onFulfillOrder,
  onSelfCollectOrder,
  navigation,
  actionsAllowed = true,
}) => {
  const isMobile = useIsMobile();
  const [visibleActionMenu, setVisibleActionMenu] = React.useState(false);
  const [updateOrder, { loading: updateOrderLoading, error: updateOrderError }] = useMutation(
    UPDATE_ORDER,
    { refetchQueries: ['ordersToDispatch'] },
  );

  const [toggleStatusFulfilled] = useMutation(TOGGLE_STATUS_FULFILLED, {
    refetchQueries: ['ordersToDispatch'],
  });

  const isReadOnlyProvider =
    !order.provider ||
    order.provider === STORE_PROVIDERS.EBAY ||
    order.provider === STORE_PROVIDERS.MAGENTO2 ||
    order.provider === STORE_PROVIDERS.ETSY;
  const [isCancelFormOpen, setCancelFormOpen] = React.useState(false);

  const isMarkAsFulfilled = `${order.fulfillmentStatus}`.toLowerCase() !== 'fulfilled';
  const isResetFulfilled = !isMarkAsFulfilled && !isNil(order.baseFulfillmentStatus);

  return (
    <>
      {actionsAllowed && navigation ? (
        <Portal>
          <Modal visible={isCancelFormOpen} onDismiss={() => setCancelFormOpen(false)}>
            <OrderCancelForm
              onDismiss={() => setCancelFormOpen(false)}
              onSubmit={() => alert('submit')}
              order={order}
              navigation={navigation}></OrderCancelForm>
          </Modal>
        </Portal>
      ) : null}
      <Formik
        initialValues={order}
        validate={(values) => {
          const errors = {};
          return errors;
        }}
        enableReinitialize
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          try {
            const {
              data: {
                updateOrder: { userErrors },
              },
            } = await updateOrder({
              variables: {
                input: {
                  id: order.id,
                  email: values.email,
                  note: values.note,
                  shippingAddress: omit(['__typename'], values.shippingAddress),
                },
                storeId: order.storeId,
              },
            });
            if (userErrors && userErrors.length > 0) {
              throw new Error(
                'Failed to update the order\n' +
                  userErrors.map(({ message }) => message).join('\n'),
              );
            }
            unifiedAlert('Successfully updated the order details.');
          } catch (e) {
            unifiedAlert(e.message);
          } finally {
            setSubmitting(false);
          }
        }}>
        {(formProps) => {
          return (
            <Card style={[styles.card, isMobile ? styles.mobileCard : styles.desktopCard]}>
              {noTitle ? null : (
                <Card.Title
                  title="Order detail"
                  subtitle={`${order?.provider} order number: ${order?.displayId}`}
                  left={(props) => <Avatar.Icon {...props} icon="receipt" />}
                  right={
                    !actionsAllowed
                      ? null
                      : (props) => (
                          <Menu
                            visible={visibleActionMenu}
                            onDismiss={() => setVisibleActionMenu(false)}
                            anchor={
                              <IconButton
                                {...props}
                                icon="dots-vertical"
                                onPress={() => setVisibleActionMenu(true)}
                              />
                            }>
                            {(onFulfillOrder && (
                              <Menu.Item
                                icon="truck"
                                onPress={() => {
                                  setVisibleActionMenu(false);
                                  onFulfillOrder();
                                }}
                                title="Fulfill order"
                              />
                            )) ||
                              null}

                            {(navigation && USER_SETTINGS.localPickupEnabled && (
                              <Menu.Item
                                icon="forklift"
                                onPress={() => {
                                  setVisibleActionMenu(false);
                                  onSelfCollectOrder();
                                }}
                                title="Self-collect"
                              />
                            )) ||
                              null}
                            {(isMarkAsFulfilled || isResetFulfilled) && (
                              <Menu.Item
                                icon={
                                  isResetFulfilled ? 'refresh-circle' : 'checkbox-marked-circle'
                                }
                                onPress={async () => {
                                  try {
                                    setVisibleActionMenu(false);
                                    let payload = {
                                      id: order.id,
                                      storeId: order.storeId,
                                      currentStatus: order.fulfillmentStatus,
                                      newStatus: 'fulfilled',
                                    };
                                    if (isResetFulfilled) {
                                      payload = {
                                        id: order.id,
                                        storeId: order.storeId,
                                        newStatus: order.baseFulfillmentStatus,
                                      };
                                    }
                                    const res = await toggleStatusFulfilled({
                                      variables: payload,
                                    });
                                    if (!res?.data?.toggleStatusFulfilled) {
                                      unifiedAlert('Failed');
                                    }
                                  } catch (error) {
                                    unifiedAlert(error.message);
                                  }
                                }}
                                title={
                                  isResetFulfilled
                                    ? 'Reset fulfillment status'
                                    : 'Mark order fulfilled'
                                }
                              />
                            )}

                            <Divider />
                            <Menu.Item
                              icon="delete"
                              onPress={() => {
                                setVisibleActionMenu(false);
                                setCancelFormOpen(true);
                              }}
                              title="Cancel order"
                            />
                          </Menu>
                        )
                  }
                />
              )}

              <Card.Content>
                <TextInputField
                  size="small"
                  label="Order No."
                  name="displayId"
                  disabled={true}
                  style={styles.field}
                  {...formProps}
                />
                <TextInputField
                  size="small"
                  label="Order Date"
                  name="createdAt"
                  disabled={true}
                  style={styles.field}
                  {...formProps}
                />
                <TextInputField
                  size="small"
                  label="Payment Status"
                  name="financialStatus"
                  disabled={true}
                  style={styles.field}
                  {...formProps}
                />
                <TextInputField
                  size="small"
                  label="Fulfillment Status"
                  name="fulfillmentStatus"
                  disabled={true}
                  style={styles.field}
                  {...formProps}
                />
                <TextInputField
                  size="small"
                  label="Sales Channel"
                  name="provider"
                  disabled={true}
                  style={styles.field}
                  {...formProps}
                />
                <TextInputField
                  size="small"
                  label="Email"
                  name="email"
                  disabled={isReadOnlyProvider}
                  style={styles.field}
                  {...formProps}
                />
                <TextInputField
                  size="small"
                  label="House Number"
                  name="shippingAddress.houseNumber"
                  disabled={isReadOnlyProvider}
                  style={styles.field}
                  {...formProps}
                />
                <TextInputField
                  size="small"
                  label="Address Line 1"
                  name="shippingAddress.address1"
                  disabled={isReadOnlyProvider}
                  style={styles.field}
                  {...formProps}
                />
                <TextInputField
                  size="small"
                  label="Address Line 2"
                  name="shippingAddress.address2"
                  disabled={isReadOnlyProvider}
                  style={styles.field}
                  {...formProps}
                />
                <TextInputField
                  size="small"
                  label="Name"
                  name="shippingAddress.name"
                  disabled={isReadOnlyProvider}
                  style={styles.field}
                  {...formProps}
                />
                <TextInputField
                  size="small"
                  label="Phone"
                  name="shippingAddress.phone"
                  disabled={isReadOnlyProvider}
                  style={styles.field}
                  {...formProps}
                />
                <TextInputField
                  size="small"
                  label="State"
                  name="shippingAddress.province"
                  disabled={isReadOnlyProvider}
                  style={styles.field}
                  {...formProps}
                />
                <TextInputField
                  size="small"
                  label="State Code"
                  name="shippingAddress.provinceCode"
                  disabled={isReadOnlyProvider}
                  style={styles.field}
                  {...formProps}
                />
                <TextInputField
                  size="small"
                  label="Postal Code"
                  name="shippingAddress.zip"
                  disabled={isReadOnlyProvider}
                  style={styles.field}
                  {...formProps}
                />
                <TextInputField
                  size="small"
                  label="City"
                  name="shippingAddress.city"
                  disabled={isReadOnlyProvider}
                  style={styles.field}
                  {...formProps}
                />
                {/* FIXME
                  Note should be modifiable
                  At this moment, temporarily disable note for Ebay
                  A further look into the API about what is supported again will be put back
               */}
                <TextInputField
                  size="small"
                  label="Country"
                  name="shippingAddress.countryCode"
                  disabled={isReadOnlyProvider}
                  style={styles.field}
                  {...formProps}
                />
                <TextInputField
                  size="small"
                  label="Note"
                  textStyle={{ minHeight: 64 }}
                  multiline
                  name="note"
                  disabled={isReadOnlyProvider}
                  style={styles.field}
                  {...formProps}
                />
              </Card.Content>

              {!isReadOnlyProvider && (
                <Card.Actions style={{ justifyContent: 'center' }}>
                  <Button
                    onPress={formProps.submitForm}
                    mode="contained"
                    loading={formProps.isSubmitting}
                    disabled={formProps.isSubmitting || !formProps.dirty}>
                    Save changes
                  </Button>
                </Card.Actions>
              )}
            </Card>
          );
        }}
      </Formik>
    </>
  );
};
