import * as React from 'react';
import { StyleSheet } from 'react-native';
import { Card, Modal, Text } from 'react-native-paper';
import { Portal } from 'react-native-paper';
import { observer } from 'mobx-react';
import { List, ListItem } from '@ui-kitten/components';
import { format } from 'date-fns';
import Table from '../../components/Table';
import Background from '../../components/Background';
import IcSearchForm from '../../components/IcSearchForm';
import CreateInboundForm from '../../components/CreateInboundForm';
import Dialogue from '../../components/Dialogue';
import DevanningAppointmentModal from '../../components/DevanningAppointmentModal';
import { IC_STATUS_MAP, IC_STATUS_CODE } from '@ezom/library/lib/cjs/constants';
import exportCsv from '../../core/csv';
import InboundControl from '../../components/InboundControl';
import { UIStatusWrapper } from '../../components/ui-status';
import { useIsFocused } from '@react-navigation/core';
import {
  inboundOrderTableStore,
  inventoryStore,
  warehouseAccountStore,
  warehousesStore,
} from '../../store';
import { WarehouseAccountSelectionModal } from '../../components/WarehouseAccountSelectionModal';
import { all } from 'ramda';
import BatchPrintSkuModal from '../../components/BatchPrintSkuModal';
import PrintSkuLabel from '../../components/PrintSkuLabel';

const getId = (item) => item.consignment_no;
const selectedIcs = (icOrders, selectedIds) =>
  icOrders.filter((icOrder) => selectedIds.has(getId(icOrder)));
const CANCELLABLE_STATUS = [
  IC_STATUS_CODE.Approved,
  IC_STATUS_CODE.Booking,
  IC_STATUS_CODE['Booking Booking confirmed'],
  IC_STATUS_CODE['Booking rejected'],
  IC_STATUS_CODE.Draft,
  IC_STATUS_CODE.Pending,
];

const MAX_NO_OF_ITEMS_AUTO_SEARCH = 200;

export default observer(({ navigation }) => {
  const isFocused = useIsFocused();
  const [isOpenBatchPrintSkuModal, setIsOpenBatchPrintSkuModal] = React.useState(false);

  const [labelPrintModalVisible, setLabelPrintModalVisible] = React.useState(false);
  const [selectedSkus, setSelectedSku] = React.useState([]);
  const skuToGeneratePdf = React.useMemo(() => {
    let skuCodes = [];

    selectedSkus.forEach((item) => {
      for (let i = 0; i < item.quantity; i++) {
        skuCodes.push(item.sku);
      }
    });
    return skuCodes.map((sku) => inventoryStore.items.find((item) => item.sku_code === sku));
  }, [selectedSkus]);

  // Refetch data when screen is focused
  React.useEffect(() => {
    if (isFocused) {
      inboundOrderTableStore.fetchItems();
    }
  }, [isFocused]);
  return (
    <Background fullWidth={true}>
      <InboundControl
        filterParams={inboundOrderTableStore.filterParams}
        indicators={getIndicators(inboundOrderTableStore.filterParams)}
        onSearchTermChange={inboundOrderTableStore.setSearchTerm}
        onPressAdvancedSearch={inboundOrderTableStore.openSearch}
        onFilterClear={() => {
          inboundOrderTableStore.resetFilterParams();
        }}
        onFilterChange={(params) => {
          inboundOrderTableStore.setFilterParams({
            ...inboundOrderTableStore.filterParams,
            ...params,
          });
        }}
        sortOptions={inboundOrderTableStore.sortOption}
        onSortChange={inboundOrderTableStore.setSortOption}
        disableSearch={inboundOrderTableStore.loading}
        requireButtonClickToSearch={inboundOrderTableStore.total > MAX_NO_OF_ITEMS_AUTO_SEARCH}
      />
      <Card>
        <UIStatusWrapper
          status={{
            error: inboundOrderTableStore.error,
            indeterminate: inboundOrderTableStore.loading,
          }}>
          <Table
            items={inboundOrderTableStore.items}
            getId={getId}
            displayKeys={inboundOrderTableStore.DISPLAY_KEYS}
            sort="single"
            defaultSortOption={inboundOrderTableStore.sortOption}
            onSortChange={inboundOrderTableStore.setSortOption}
            heightOffset={300}
            titleByKey={inboundOrderTableStore.TITLE_BY_KEY}
            formatterByKey={inboundOrderTableStore.FORMATTER_BY_KEY}
            pagingPrefixText={'Up to '}
            pagingSuffixText={' inbounds per service provider'}
            rowOnClick={(item) =>
              navigation.navigate('InboundDetail', {
                consignmentNumber: item.consignment_no,
                warehouseAccountId: item.warehouseAccountId,
              })
            }
            isFabVisible={isFocused}
            currentPage={inboundOrderTableStore.currentPage}
            onPageChange={(page) => {
              inboundOrderTableStore.setCurrentPage(page);
            }}
            totalItemNum={inboundOrderTableStore.total}
            itemsPerPage={inboundOrderTableStore.pageSize}
            setPageSize={inboundOrderTableStore.setPageSize}
            fabActions={(selectedIds) => {
              const actions = [];
              actions.push({
                icon: 'printer',
                label: 'Print SKU label',
                onPress: () => setIsOpenBatchPrintSkuModal(true),
              });
              if (selectedIds.size === 0) {
                actions.push({
                  icon: 'magnify',
                  label: 'Search consignments',
                  onPress: inboundOrderTableStore.openSearch,
                });
                actions.push({
                  icon: 'plus',
                  label: 'Add inbound consignment',
                  onPress: () => {
                    if (warehouseAccountStore.selectedWarehouseAccount) {
                      inboundOrderTableStore.openConsignmentForm();
                    } else {
                      inboundOrderTableStore.setOnWarehouseSelectionSubmit((warehouseAccount) => {
                        warehouseAccountStore.setSelectedWarehouseAccount(warehouseAccount);
                        inboundOrderTableStore.openConsignmentForm();
                      });
                      inboundOrderTableStore.setWarehouseSelectionVisible(true);
                    }
                  },
                });
                actions.push({
                  icon: 'download-outline',
                  label: 'Export all',
                  onPress: async () => {
                    await inboundOrderTableStore.fetchItems(true);
                    const ics = inboundOrderTableStore.exportedItems.map(
                      ({ lstsku, __typename, create_time, ...fields }) => ({
                        ...fields,
                        create_time: new Date(Number(create_time)).toLocaleString(),
                      }),
                    );
                    const csvHeaders = ics?.length > 0 ? Object.keys(ics[0]) : [];
                    exportCsv(ics, csvHeaders, 'icOrders.csv');
                  },
                });
              }
              if (selectedIds.size > 0) {
                actions.push({
                  icon: 'download-outline',
                  label: `Export selected ${selectedIds.size > 1 ? 'orders' : 'order'}`,
                  onPress: () => {
                    const ics = selectedIcs(inboundOrderTableStore.items, selectedIds);
                    const exportedIcs = ics.map(
                      ({ lstsku, __typename, create_time, ...fields }) => ({
                        ...fields,
                        create_time: new Date(Number(create_time)).toLocaleString(),
                      }),
                    );
                    const csvHeaders = exportedIcs?.length > 0 ? Object.keys(exportedIcs[0]) : [];
                    exportCsv(exportedIcs, csvHeaders, 'icOrders.csv');
                  },
                });
                if (
                  all(
                    (item) => CANCELLABLE_STATUS.includes(item.status),
                    selectedIcs(inboundOrderTableStore.items, selectedIds),
                  )
                ) {
                  actions.push({
                    icon: 'cancel',
                    label: `Cancel selected ${selectedIds.size > 1 ? 'orders' : 'order'}`,
                    onPress: () => {
                      inboundOrderTableStore.ordersToCancel = selectedIds;
                      inboundOrderTableStore.openCancelDialogue();
                    },
                  });
                }
                if (
                  !selectedIcs(inboundOrderTableStore.items, selectedIds).some(
                    (ic) => IC_STATUS_MAP[ic.status] != IC_STATUS_MAP.A,
                  )
                ) {
                  actions.push({
                    icon: 'calendar',
                    label: `Book devanning`,
                    onPress: () => {
                      inboundOrderTableStore.setSelectedIds(selectedIds);
                      inboundOrderTableStore.setAppointmentFormOpen(true);
                    },
                  });
                }
              }
              return actions;
            }}
          />
        </UIStatusWrapper>
      </Card>
      <Portal>
        <Modal
          visible={inboundOrderTableStore.searchOpen}
          onDismiss={inboundOrderTableStore.closeSearch}
          contentContainerStyle={styles.modalStyle}>
          <IcSearchForm
            searchParams={inboundOrderTableStore.filterParams}
            items={inboundOrderTableStore.items}
            onDismiss={inboundOrderTableStore.closeSearch}
            onSubmit={(filterParams) => {
              inboundOrderTableStore.setFilterParams(filterParams);
              inboundOrderTableStore.closeSearch();
            }}
          />
        </Modal>
      </Portal>

      <Portal>
        <Modal
          visible={inboundOrderTableStore.consignmentFormOpen}
          onDismiss={inboundOrderTableStore.closeConsignmentForm}
          contentContainerStyle={styles.modalStyle}>
          <CreateInboundForm
            warehouseAccount={warehouseAccountStore.selectedWarehouseAccount}
            onDismiss={inboundOrderTableStore.closeConsignmentForm}
            onSubmit={() => {
              setTimeout(() => inboundOrderTableStore.fetchItems(), 2000);
              inboundOrderTableStore.closeConsignmentForm();
            }}
          />
        </Modal>
      </Portal>

      <DevanningAppointmentModal
        visible={inboundOrderTableStore.appointmentFormOpen}
        setVisible={inboundOrderTableStore.setAppointmentFormOpen}
        consignmentNumbers={selectedIcs(
          inboundOrderTableStore.items,
          inboundOrderTableStore.selectedIds,
        )
          .filter((ic) => IC_STATUS_MAP[ic.status] === IC_STATUS_MAP.A)
          .map((ic) => ic.consignment_no)}
      />

      <Portal>
        <Modal
          visible={inboundOrderTableStore.cancelDialogueOpen}
          onDismiss={inboundOrderTableStore.closeCancelDialogue}
          contentContainerStyle={styles.modalStyle}>
          <Dialogue
            onSubmit={async () => {
              await Promise.all(
                selectedIcs(
                  inboundOrderTableStore.items,
                  inboundOrderTableStore.ordersToCancel,
                ).map((ic) =>
                  inboundOrderTableStore.dataStore.cancelItem(
                    ic.warehouseAccountId,
                    ic.consignment_no,
                  ),
                ),
              );
              setTimeout(() => inboundOrderTableStore.fetchItems(), 2000);
              inboundOrderTableStore.closeCancelDialogue();
            }}
            onDismiss={inboundOrderTableStore.closeCancelDialogue}>
            <Text>Are you sure you want to cancel the consignments?</Text>
            <List
              data={selectedIcs(
                inboundOrderTableStore.items,
                inboundOrderTableStore.ordersToCancel,
              )}
              renderItem={({ item }) => <ListItem title={`${item.consignment_no}`} />}
            />
          </Dialogue>
        </Modal>
      </Portal>

      <BatchPrintSkuModal
        visible={isOpenBatchPrintSkuModal}
        onDismiss={() => {
          setIsOpenBatchPrintSkuModal(false);
          setSelectedSku([]);
        }}
        onSubmit={() => {
          setLabelPrintModalVisible(true);
          setIsOpenBatchPrintSkuModal(false);
        }}
        setSelectedSku={setSelectedSku}
        selectedSkus={selectedSkus}
      />

      <Portal>
        <Modal
          visible={labelPrintModalVisible}
          onDismiss={() => {
            setLabelPrintModalVisible(false);
          }}
          contentContainerStyle={styles.modalStyle}>
          <PrintSkuLabel skuToGeneratePdf={skuToGeneratePdf} />
        </Modal>
      </Portal>

      <WarehouseAccountSelectionModal
        visible={inboundOrderTableStore.warehouseSelectionVisible}
        warehouseAccounts={warehouseAccountStore.warehouseAccounts}
        loading={warehouseAccountStore.loading}
        error={warehouseAccountStore.error}
        onSubmit={(warehouseAccount) => {
          inboundOrderTableStore.onWarehouseSelectionSubmit(warehouseAccount);
          inboundOrderTableStore.setWarehouseSelectionVisible(false);
        }}
        onCancel={() => inboundOrderTableStore.setWarehouseSelectionVisible(false)}
      />
    </Background>
  );
});

const styles = StyleSheet.create({
  modalStyle: { backgroundColor: 'white', padding: 20, maxWidth: 800, marginHorizontal: 'auto' },
  header: { alignSelf: 'center', paddingVertical: 20, fontWeight: 500 },
  field: {
    marginVertical: 8,
  },
  qtyInput: {
    flex: 1,
    maxWidth: '40px',
  },
  mobileInput: {
    maxWidth: '200px',
    minWidth: '200px',
    marginHorizontal: '5px',
  },
});

const getIndicators = (params) => {
  const dateFormatter = (date) => format(date, 'd MMM y');
  let indicators = [];

  if (params.consignmentNumber) {
    indicators.push(`consignment no.: ${params.consignmentNumber}`);
  } else {
    if (params.refNumber) {
      indicators.push(`ref. no.: ${params.refNumber}`);
    } else {
      if (params.status) {
        indicators.push(`status: ${IC_STATUS_MAP[params.status]}`);
      }

      if (params.warehouse_code) {
        indicators.push(
          `warehouse: ${warehousesStore.getWarehouseNameEn(
            params.warehouse_code,
            warehouseAccountStore.selectedWarehouseAccount,
          )}`,
        );
      }

      if (params.create_time_start && params.create_time_end) {
        indicators.push(
          `orders from ${dateFormatter(params.create_time_start)} to ${dateFormatter(
            params.create_time_end,
          )}`,
        );
      } else if (params.create_time_start) {
        indicators.push(`orders from ${dateFormatter(params.create_time_start)}`);
      } else if (params.create_time_end) {
        indicators.push(`orders by ${dateFormatter(params.create_time_end)}`);
      }
    }
  }

  return indicators;
};
