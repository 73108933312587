import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Dimensions, ScrollView, View } from 'react-native';
import { Button, Card, Chip, Text, Title } from 'react-native-paper';
import {
  getId,
  getIndicators,
  MAX_NO_OF_ITEMS_AUTO_SEARCH,
} from '../../screens/shipments/CourierShipmentList';
import {
  courierShipmentSelectionTableStore,
  courierShipmentStore,
  warehouseAccountStore,
} from '../../store';
import CourierShipmentListControl from '../CourierShipmentListControl';
import Table from '../Table';
import BatchTrackingShipmentResultModal from './BatchTrackingShipmentResultModal';

const BatchTrackingShipmentSelection = observer(
  ({ shipmentCourierList, selectedRows: selectedRowsProps = undefined }) => {
    const windowHeight = Dimensions.get('window').height;
    const [selectedIds, setSelectedIds] = useState(new Set());
    const [selectedRows, setSelectedRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [trackingShipmentResultModal, setTrackingShipmentResultModal] = React.useState({
      data: null,
      isOpen: false,
    });

    useEffect(() => {
      if (selectedRowsProps?.length) {
        setSelectedIds(new Set(selectedRowsProps.map(getId)));
        setSelectedRows(selectedRowsProps);
      }
    }, [selectedRowsProps?.length]);

    useEffect(() => {
      if (warehouseAccountStore.selectedWarehouseAccount) {
        courierShipmentSelectionTableStore.fetchItems();
      }
    }, [warehouseAccountStore.selectedWarehouseAccount]);

    const isSelectedOrder = [...selectedIds].length > 0;

    return (
      <View>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 10,
          }}>
          <Title>Selected Courier Shipment Ids</Title>
          <View style={{ alignItems: 'center', flexDirection: 'row' }}>
            {isSelectedOrder && (
              <Button
                style={{ marginRight: 10 }}
                disabled={loading}
                icon={'close-circle'}
                mode="outlined"
                onPress={() => {
                  setSelectedIds(new Set());
                  setSelectedRows([]);
                }}>
                Clear selected
              </Button>
            )}
            <Button
              loading={loading}
              icon={'radar'}
              mode="contained"
              disabled={!isSelectedOrder}
              onPress={async () => {
                try {
                  setLoading(true);
                  let trackingInfo = [];
                  trackingInfo = await courierShipmentStore.trackShipmentOrder(
                    warehouseAccountStore.selectedWarehouseAccount?.id,
                    [...selectedIds],
                  );
                  setTrackingShipmentResultModal({ data: trackingInfo, isOpen: true });
                } catch (e) {
                  console.log(e);
                } finally {
                  setLoading(false);
                }
              }}>
              Batch Tracking
            </Button>
          </View>
        </View>
        {isSelectedOrder ? (
          <ScrollView
            style={{ maxHeight: 100, marginBottom: 10 }}
            contentContainerStyle={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            {[...selectedIds].map((id) => {
              return (
                <Chip
                  style={{ margin: 5 }}
                  onClose={() => {
                    setSelectedIds((prev) => {
                      const newSet = new Set(prev);
                      newSet.delete(id);
                      return newSet;
                    });
                    setSelectedRows(selectedRows.filter((r) => getId(r) !== id));
                  }}>
                  {id}
                </Chip>
              );
            })}
          </ScrollView>
        ) : (
          <Text style={{ color: 'red' }}>Please select orders to tracking</Text>
        )}
        <BatchTrackingShipmentResultModal
          data={trackingShipmentResultModal.data}
          visible={trackingShipmentResultModal.isOpen}
          onClose={() => setTrackingShipmentResultModal({ data: null, isOpen: false })}
        />
        <CourierShipmentListControl
          filterParams={courierShipmentSelectionTableStore.filterParams}
          indicators={getIndicators(courierShipmentSelectionTableStore.filterParams)}
          onSearchTermChange={courierShipmentSelectionTableStore.setSearchTerm}
          onFilterClear={() => {
            courierShipmentSelectionTableStore.resetFilterParams();
          }}
          onFilterChange={(params) => {
            courierShipmentSelectionTableStore.setFilterParams({
              ...courierShipmentSelectionTableStore.filterParams,
              ...params,
            });
          }}
          courierList={shipmentCourierList}
          sortOptions={courierShipmentSelectionTableStore.sortOption}
          onSortChange={courierShipmentSelectionTableStore.setSortOption}
          disableSearch={
            courierShipmentSelectionTableStore.loading ||
            !warehouseAccountStore.selectedWarehouseAccount
          }
          requireButtonClickToSearch={
            courierShipmentSelectionTableStore.total > MAX_NO_OF_ITEMS_AUTO_SEARCH
          }
        />
        <Card style={{ marginBottom: 20 }}>
          <Table
            keepSelectedWhenPageChange
            selectedRows={selectedRows}
            onSelectedRowsChange={({ selectedIds, selectedRows }) => {
              setSelectedIds(selectedIds);
              setSelectedRows(selectedRows);
            }}
            error={courierShipmentSelectionTableStore.error}
            loading={
              courierShipmentSelectionTableStore.loading ||
              !warehouseAccountStore.selectedWarehouseAccount
            }
            // because this modal with height 90% => heightOffset = 10% + 300 px of another component
            heightOffset={400 + (windowHeight * 10) / 100}
            items={courierShipmentSelectionTableStore.items}
            getId={getId}
            displayKeys={courierShipmentSelectionTableStore.DISPLAY_KEYS}
            titleByKey={courierShipmentSelectionTableStore.TITLE_BY_KEY}
            formatterByKey={courierShipmentSelectionTableStore.FORMATTER_BY_KEY}
            sort="single"
            isFabVisible={false}
            currentPage={courierShipmentSelectionTableStore.currentPage}
            onPageChange={(page) => {
              courierShipmentSelectionTableStore.setCurrentPage(page);
            }}
            totalItemNum={courierShipmentSelectionTableStore.total}
            itemsPerPage={courierShipmentSelectionTableStore.pageSize}
            setPageSize={courierShipmentSelectionTableStore.setPageSize}
          />
        </Card>
      </View>
    );
  },
);

export default BatchTrackingShipmentSelection;
