import * as React from 'react';
import { StyleSheet } from 'react-native';
import { Card, Text } from 'react-native-paper';
import { Input, Layout, Toggle } from '@ui-kitten/components';
import { useAssets } from 'expo-asset';
import Button from './Button';
import { mergeAll, range, splitEvery, zipObj } from 'ramda';

const generateMultA4PageSkuSchema = (
  ic,
  template,
  printCountryOfOrigin,
  countryOfOrigin,
  maxItemsPerPage,
  xGap = 100,
  yGap = 28,
) => {
  const splitedSkus = splitEvery(maxItemsPerPage, ic.lstsku);
  const fieldKeys = range(0, maxItemsPerPage).map((i) =>
    Object.keys(template.schemas[0]).map((key) => `${key}_${i}`),
  );
  const fieldDefs = range(0, maxItemsPerPage).map((i) =>
    Object.values(template.schemas[0]).map((val) => ({
      ...val,
      position: {
        x: val.position.x + (i % 2) * xGap + 15,
        y: val.position.y + Math.floor(i / 2) * yGap + 3,
      },
    })),
  );
  const inputs = splitedSkus
    .map((skus, index) =>
      skus.map((sku, i) => ({
        [`skuBarCode_${i}`]: sku.sku_id,
        [`skuCode_${i}`]: sku.sku_code,
        [`skuNameCN_${i}`]: sku.sku_name,
        [`skuNameEN_${i}`]: sku.specification,
        [`Note_${i}`]: printCountryOfOrigin ? countryOfOrigin : '',
        [`skuNameTitle_${i}`]: 'Name:',
      })),
    )
    .map((input) => mergeAll(input));
  const schemas = [
    mergeAll(range(0, maxItemsPerPage).map((i) => zipObj(fieldKeys[i], fieldDefs[i]))),
  ];
  return { schemas, inputs };
};

export default ({ skuToGeneratePdf }) => {
  const [countryOfOrigin, setCountryOfOrigin] = React.useState('Made in China');
  const [printCountryOfOrigin, setPrintCountryOfOrigin] = React.useState(false);

  const [btnDisabled, setBtnDisabled] = React.useState(false);

  const [fonts] = useAssets([
    require('../pdfTemplates/fonts/cn.ttf'),
    require('../pdfTemplates/fonts/Helvetica.ttf'),
  ]);

  const generatePdfFromTempalte = React.useCallback(
    async (template, inputs = []) => {
      const url = fonts[0].localUri;
      const fontBytes = await fetch(url).then((res) => res.arrayBuffer());
      const url2 = fonts[1].localUri;
      const fontBytes2 = await fetch(url2).then((res) => res.arrayBuffer());
      const font = { CN: fontBytes, Helvetica: fontBytes2 };
      const labelmake = (await import('labelmake')).default;
      const pdf = await labelmake({
        template: { ...template, fontName: 'Helvetica' },
        inputs,
        font,
      });
      const blob = new Blob([pdf.buffer], { type: 'application/pdf' });
      const page = await window.open(URL.createObjectURL(blob), '_blank');
      return page;
    },
    [fonts],
  );
  return (
    <>
      <Card
        header={(props) => (
          <Text category="h6" style={styles.header}>
            Print SKU labels
          </Text>
        )}
        style={{ marginBottom: 5 }}
        disabled>
        <Layout style={styles.container}>
          <Layout style={{ marginBottom: 4 }}>
            <Input
              value={countryOfOrigin}
              onChangeText={(nextValue) => setCountryOfOrigin(nextValue)}
              accessoryLeft={() => (
                <Toggle
                  checked={printCountryOfOrigin}
                  onChange={(nextChecked) => setPrintCountryOfOrigin(nextChecked)}>
                  Print country of origin
                </Toggle>
              )}
            />
          </Layout>
          <Button
            style={styles.button}
            appearance="outline"
            size="small"
            disabled={btnDisabled}
            onPress={async () => {
              setBtnDisabled(true);
              const template = await import('../pdfTemplates/skuLabels/sku_70x22_template.json');

              await generatePdfFromTempalte(
                template,
                skuToGeneratePdf.map((sku) => ({
                  skuBarCode: sku.sku_id,
                  skuCode:
                    sku.sku_code.length > 40 ? sku.sku_code.substring(0, 40) + '...' : sku.sku_code,
                  skuNameCN: getSkuNameCN(sku, 35),
                  skuNameEN:
                    sku.specification && sku.specification.length > 25
                      ? sku.specification.substring(0, 25) + '...'
                      : sku.specification,
                  Note: printCountryOfOrigin ? countryOfOrigin : '',
                })),
              );
              setBtnDisabled(false);
            }}>
            Label 7cmX2.2cm
          </Button>
          <Button
            style={styles.button}
            appearance="outline"
            size="small"
            disabled={btnDisabled}
            onPress={async () => {
              setBtnDisabled(true);
              const template = await import('../pdfTemplates/skuLabels/sku_70x40_template.json');
              await generatePdfFromTempalte(
                template,
                skuToGeneratePdf.map((sku) => ({
                  skuBarCode: sku.sku_id,
                  skuCode:
                    sku.sku_code.length > 33 ? sku.sku_code.substring(0, 33) + '...' : sku.sku_code,
                  skuNameCN: getSkuNameCN(sku, 25),
                  Note: printCountryOfOrigin ? countryOfOrigin : '',
                  skuNameTitle: 'Name:',
                })),
              );
              setBtnDisabled(false);
            }}>
            Label 7cmX4cm
          </Button>
        </Layout>
        <Layout style={styles.container}>
          <Button
            style={styles.button}
            appearance="outline"
            size="small"
            disabled={btnDisabled}
            onPress={async () => {
              setBtnDisabled(true);
              const template = await import('../pdfTemplates/skuLabels/sku_70x22_template.json');

              const { schemas, inputs } = generateMultA4PageSkuSchema(
                {
                  lstsku: skuToGeneratePdf.map((sku) => ({
                    ...sku,
                    sku_code:
                      sku.sku_code.length > 40
                        ? sku.sku_code.substring(0, 40) + '...'
                        : sku.sku_code,
                    sku_name: getSkuNameCN(sku, 35),
                    specification:
                      sku.specification && sku.specification.length > 25
                        ? sku.specification.substring(0, 25) + '...'
                        : sku.specification,
                  })),
                },
                template,
                printCountryOfOrigin,
                countryOfOrigin,
                20,
                100,
                28,
              );
              await generatePdfFromTempalte(
                { schemas, basePdf: { width: 210, height: 297 } },
                inputs,
              );
              setBtnDisabled(false);
            }}>
            A4 7cmX2.2cm
          </Button>
          <Button
            style={styles.button}
            appearance="outline"
            size="small"
            disabled={btnDisabled}
            onPress={async () => {
              setBtnDisabled(true);

              const template = await import('../pdfTemplates/skuLabels/sku_70x40_template.json');
              const { schemas, inputs } = generateMultA4PageSkuSchema(
                {
                  lstsku: skuToGeneratePdf.map((sku) => ({
                    ...sku,
                    sku_code:
                      sku.sku_code.length > 33
                        ? sku.sku_code.substring(0, 33) + '...'
                        : sku.sku_code,
                    sku_name: getSkuNameCN(sku, 25),
                  })),
                },
                template,
                printCountryOfOrigin,
                countryOfOrigin,
                12,
                100,
                45,
              );
              await generatePdfFromTempalte(
                { schemas, basePdf: { width: 210, height: 297 } },
                inputs,
              );
              setBtnDisabled(false);
            }}>
            A4 7cmX4cm
          </Button>
        </Layout>
      </Card>
    </>
  );
};

const styles = StyleSheet.create({
  modalStyle: { backgroundColor: 'white', padding: 20, maxWidth: 800, marginHorizontal: 'auto' },
  header: { alignSelf: 'center', paddingVertical: 20, fontWeight: 500 },
  field: {
    marginVertical: 8,
  },
  qtyInput: {
    flex: 1,
    maxWidth: '40px',
  },
  mobileInput: {
    maxWidth: '200px',
    minWidth: '200px',
    marginHorizontal: '5px',
  },
});

const getSkuNameCN = (sku, maxLength) => {
  // for backward compatibility, sku_name can be null
  return (sku.sku_name || sku.sku_code).length > maxLength
    ? (sku.sku_name || sku.sku_code).substring(0, maxLength + 1) + '...'
    : sku.sku_name || sku.sku_code;
};
