import { makeAutoObservable } from 'mobx';
import { sum, transpose, zipObj, prop, map, groupBy } from 'ramda';
import { unixMillisecondToDate } from '../core/utils/utils';
import { gql } from '@apollo/client';
import { OC_TYPE_MAP, OC_STATUS_MAP } from '@ezom/library/lib/cjs/constants';

const DEFAUL_PARAMS = {
  refNumber: undefined,
  consignmentNumber: undefined,
  shippingNumber: undefined,
  status: undefined,
  sku_code: undefined,
  from_warehouse_code: undefined,
  logistics_product_code: undefined,
  seller_id: undefined,
  sales_platform: undefined,
  sales_no: undefined,
  country: undefined,
  create_time_start: undefined,
  create_time_end: undefined,
  complete_time_start: undefined,
  complete_time_end: undefined,
  page_no: undefined,
  page_size: undefined,
  customer_code: undefined,
  pickingTaskType: undefined,
  organisationId: undefined,
};

const OUTBOUND_ORDER_QUERY = gql`
  query outbounds(
    $warehouseAccountIds: [ID!]!
    $refNumbers: [ID!]
    $consignmentNumbers: [ID!]
    $shippingNumbers: [String!]
    $status: OutboundStatus
    $sku_code: [String!]
    $from_warehouse_code: ID
    $logistics_product_code: String
    $seller_id: String
    $sales_platform: String
    $sales_no: String
    $country: String
    $create_time_start: Float
    $create_time_end: Float
    $complete_time_start: Float
    $complete_time_end: Float
    $page_no: Int
    $page_size: Int
    $customer_code: String
    $pickingTaskType: String
    $organisationId: ID
  ) {
    outbounds(
      warehouseAccountIds: $warehouseAccountIds
      refNumbers: $refNumbers
      consignmentNumbers: $consignmentNumbers
      shippingNumbers: $shippingNumbers
      status: $status
      sku_code: $sku_code
      from_warehouse_code: $from_warehouse_code
      logistics_product_code: $logistics_product_code
      seller_id: $seller_id
      sales_platform: $sales_platform
      sales_no: $sales_no
      country: $country
      create_time_start: $create_time_start
      create_time_end: $create_time_end
      complete_time_start: $complete_time_start
      complete_time_end: $complete_time_end
      page_no: $page_no
      page_size: $page_size
      customer_code: $customer_code
      pickingTaskType: $pickingTaskType
      organisationId: $organisationId
    ) {
      consignments {
        organisationId
        warehouseAccountId
        consignment_type
        consignment_no
        ref_no
        sales_no
        from_warehouse_code
        logistics_product_code
        logistics_provider
        shipping_no
        status
        total_volume
        total_weight
        create_time
        complete_time
        remark
        first_name
        last_name
        country
        state
        city
        phone
        email
        post_code
        company
        street
        pickingTaskType
        outboundlist_sku {
          sku_id
          sku_code
          qty
        }
      }
      total
      page_no
      page_size
    }
  }
`;

const TOTAL_STATUS_QUERY = gql`
  query outbounds(
    $warehouseAccountIds: [ID!]!
    $status: OutboundStatus
    $page_no: Int
    $page_size: Int
  ) {
    outbounds(
      warehouseAccountIds: $warehouseAccountIds
      status: $status
      page_no: $page_no
      page_size: $page_size
    ) {
      consignments {
        warehouseAccountId
        status
      }
      page_no
      page_size
    }
  }
`;

const CREATE_DRAFT_PICKING_TASK = gql`
  mutation createDraftPickingTask($ocIds: [ID!]!, $warehouseAccountId: ID!) {
    createDraftPickingTask(ocIds: $ocIds, warehouseAccountId: $warehouseAccountId) {
      volume
      weight
      skuTypes
      qtyToPick
      type
      pickLocations
      dispatchChannel
      ocIds
      warehouseAccountId
    }
  }
`;

const CREATE_PICKING_TASK = gql`
  mutation createPickingTask($ocIds: [ID!]!, $warehouseAccountId: ID!) {
    createPickingTask(ocIds: $ocIds, warehouseAccountId: $warehouseAccountId) {
      id
    }
  }
`;

export class OutboundOrderTableStore {
  searchOpen = false;
  consignmentFormOpen = false;
  filterParams = DEFAUL_PARAMS;
  ordersToCancel = new Set();
  cancelDialogueOpen = false;
  sortOption = null;
  searchTerm = '';
  loading = true;
  error = null;
  cachedItems = null;
  exportedItems = null; // Store items for exporting csv
  currentPage = 0;
  pageSize = 20;
  total = null;
  cachedCouriers = null;
  csvModalOpen = false;
  xeroCsvModalOpen = false;
  importedXeroOrders = null;
  warehouseSelectionVisible = false;
  onWarehouseSelectionSubmit = () => {};
  ordersToCreatePickingWaveTask = new Set();
  createPickingWaveTaskModalOpen = false;
  draftPickingTask = null;
  pickingTask = null;
  countStatuses = {};
  duplicatedOutboundId = '';

  DISPLAY_KEYS = [
    'consignment_no',
    'organisationName',
    'consignment_type',
    'ref_no',
    'sales_no',
    'from_warehouse_code',
    'status',
    'logistics_product_code',
    'shipping_no',
    'create_time',
    'complete_time',
    'pickingTaskType',
  ];

  TITLE_BY_KEY = {
    consignment_no: 'Consignment No.',
    consignment_type: 'Type',
    from_warehouse_code: 'Warehouse',
    ref_no: 'Ref No.',
    sales_no: 'Sales No.',
    status: 'Status',
    logistics_product_code: 'Courier',
    shipping_no: 'Tracking',
    create_time: 'Created Time',
    complete_time: 'Dispatched Time',
    pickingTaskType: 'Picking Type',
    organisationName: 'Organisation Name',
  };

  get FORMATTER_BY_KEY() {
    return {
      status: (s) => OC_STATUS_MAP[s],
      consignment_type: (t) => OC_TYPE_MAP[t],
      create_time: unixMillisecondToDate,
      complete_time: unixMillisecondToDate,
      logistics_product_code: (code, oc) =>
        (this.couriers[oc.warehouseAccountId] || []).find((c) => c.logistics_product_code === code)
          ?.logistics_product_name_en || code,
      from_warehouse_code: (w) =>
        this.warehousesStore.getWarehouseNameEn(
          w,
          this.warehouseAccountStore.selectedWarehouseAccount,
        ),
    };
  }

  constructor(
    client,
    outboundOrderStore,
    warehousesStore,
    warehouseAccountStore,
    courierStore,
    organisationStore,
  ) {
    this.dataStore = outboundOrderStore;
    this.warehousesStore = warehousesStore;
    this.warehouseAccountStore = warehouseAccountStore;
    this.courierStore = courierStore;
    this.organisationStore = organisationStore;
    this.client = client;
    makeAutoObservable(
      this,
      {
        dataStore: false,
        client: false,
      },
      { autoBind: true },
    );
  }

  openSearch = () => {
    this.searchOpen = true;
  };

  closeSearch = () => {
    this.searchOpen = false;
  };

  openConsignmentForm = () => {
    this.consignmentFormOpen = true;
  };

  closeConsignmentForm = () => {
    this.consignmentFormOpen = false;
    this.duplicatedOutboundId = '';
  };

  closeCancelDialogue = () => {
    this.cancelDialogueOpen = false;
  };

  openCancelDialogue = () => {
    this.cancelDialogueOpen = true;
  };

  openCsvModal = () => {
    this.csvModalOpen = true;
  };

  closeCsvModal = () => {
    this.csvModalOpen = false;
  };

  openXeroCsvModal = () => {
    this.xeroCsvModalOpen = true;
  };

  closeXeroCsvModal = () => {
    this.xeroCsvModalOpen = false;
  };

  setImportedXeroOrders = (orders) => {
    this.importedXeroOrders = orders;
  };

  setFilterParams = (params) => {
    this.filterParams = params;
    this.cachedItems = null;
  };

  resetFilterParams = () => {
    this.filterParams = DEFAUL_PARAMS;
    this.searchTerm = '';
    this.cachedItems = null;
    this.cachedCouriers = null;
  };

  setSortOption = (option) => {
    this.sortOption = option;
  };

  setSearchTerm = (term) => {
    this.searchTerm = term;
    this.cachedItems = null;
    this.cachedCouriers = null;
  };

  setCurrentPage(page) {
    this.currentPage = page;
    this.cachedItems = null;
    this.cachedCouriers = null;
  }

  setPageSize = (pageSize) => {
    this.pageSize = pageSize;
    this.cachedItems = null;
    this.cachedCouriers = null;
  };

  /**
   * Fetch couriers per warehouse account and store them in a object {key: warehouseAccountId, val: courierList}
   * @param {*} warehouseCodesByWarehouseAccountId
   */
  async fetchCouriers(warehouseCodesByWarehouseAccountId) {
    const couriersList = await Promise.all(
      Object.entries(warehouseCodesByWarehouseAccountId).map(
        async ([warehouseAccountId, warehouseCodes]) => {
          const couriers = await this.courierStore.getCouriers(warehouseAccountId, warehouseCodes);
          return couriers;
        },
      ),
    );
    this.cachedCouriers = zipObj(Object.keys(warehouseCodesByWarehouseAccountId), couriersList);
  }

  get couriers() {
    if (!this.cachedCouriers && this.items.length > 0) {
      const warehouseCodesByWarehouseAccountId = map(
        (item) => item[0].warehouse_code,
        // Group couriers by their warehouse account
        groupBy(
          prop('warehouseAccountId'),
          Object.values(
            // Create a object with the key `${i.warehouseAccountId}+${i.from_warehouse_code}` for deduplication
            zipObj(
              ...transpose(
                this.items
                  .filter((i) => i.from_warehouse_code)
                  .map((i) => [
                    `${i.warehouseAccountId}+${i.from_warehouse_code}`,
                    {
                      warehouseAccountId: i.warehouseAccountId,
                      warehouse_code: i.from_warehouse_code,
                    },
                  ]),
              ),
            ),
          ),
        ),
      );
      this.fetchCouriers(warehouseCodesByWarehouseAccountId);
    }
    return this.cachedCouriers || [];
  }

  async fetchItems(forExport = false) {
    this.loading = true;
    try {
      if (this.searchTerm) {
        let total = 0;
        let consignments = [];

        for await (let idField of ['consignmentNumbers', 'refNumbers', 'shippingNumbers']) {
          const { data } = await this.client.query({
            query: OUTBOUND_ORDER_QUERY,
            variables: {
              ...this.filterParams,
              create_time_start: this.filterParams.create_time_start?.getTime(),
              create_time_end: this.filterParams.create_time_end?.getTime(),
              complete_time_start: this.filterParams.complete_time_start?.getTime(),
              complete_time_end: this.filterParams.complete_time_end?.getTime(),
              [idField]: [this.searchTerm],
              warehouseAccountIds: this.warehouseAccountStore.warehouseAccountIds,
            },
            fetchPolicy: 'no-cache',
          });
          consignments = consignments.concat(data.outbounds.consignments);
          total += data.outbounds.total;
        }

        // Assigns the fetched item to either the `exportedItems` or `cachedItems` property
        // Depending on whether the data is being fetched for export or not.
        if (forExport) this.exportedItems = consignments;
        else this.cachedItems = consignments;

        this.total = total;
      } else {
        let variables = {
          ...this.filterParams,
          consignmentNumbers: this.filterParams?.consignmentNumber
            ? this.filterParams.consignmentNumber.split('\n').map((x) => x.trim())
            : undefined,
          refNumbers: this.filterParams?.refNumber
            ? this.filterParams.refNumber.split('\n').map((x) => x.trim())
            : undefined,
          shippingNumbers: this.filterParams?.shippingNumber
            ? this.filterParams.shippingNumber.split('\n').map((x) => x.trim())
            : undefined,
          sku_code: this.filterParams?.sku_code
            ? this.filterParams.sku_code.split(',').map((x) => x.trim())
            : undefined,
          create_time_start: this.filterParams.create_time_start?.getTime(),
          create_time_end: this.filterParams.create_time_end?.getTime(),
          complete_time_start: this.filterParams.complete_time_start?.getTime(),
          complete_time_end: this.filterParams.complete_time_end?.getTime(),
          warehouseAccountIds: this.warehouseAccountStore.warehouseAccountIds,
        };

        if (!forExport) {
          variables.page_size = this.pageSize;
          variables.page_no = this.currentPage + 1;
        }

        const {
          data: { outbounds },
        } = await this.client.query({
          query: OUTBOUND_ORDER_QUERY,
          variables,
          fetchPolicy: 'no-cache',
        });

        // Assigns the fetched item to either the `exportedItems` or `cachedItems` property
        // Depending on whether the data is being fetched for export or not.
        if (forExport) this.exportedItems = outbounds.consignments;
        else this.cachedItems = outbounds.consignments;

        this.total = outbounds.total;
      }
      this.error = null;
    } catch (error) {
      this.error = error;
    } finally {
      this.loading = false;
    }
  }

  async fetchTotalStatuses() {
    try {
      this.loading = true;
      const totalItemsResponse = await this.client.query({
        query: TOTAL_STATUS_QUERY,
        variables: {
          status: this.filterParams.status,
          warehouseAccountIds: this.warehouseAccountStore.warehouseAccountIds,
        },
        fetchPolicy: 'no-cache',
      });
      const rs = totalItemsResponse.data.outbounds.consignments;
      const counts = {};
      Object.keys(OC_STATUS_MAP).forEach((item) => {
        counts[item] = rs.filter((x) => x.status === item).length || 0;
      });
      this.countStatuses = counts;
      this.error = null;
    } catch (error) {
      this.error = error;
    } finally {
      this.loading = false;
    }
  }

  async createDraftPickingTask(ocIds, warehouseAccountId) {
    try {
      this.loading = true;
      const {
        data: { createDraftPickingTask },
      } = await this.client.mutate({
        mutation: CREATE_DRAFT_PICKING_TASK,
        variables: {
          ocIds,
          warehouseAccountId,
        },
      });
      this.draftPickingTask = createDraftPickingTask;
      this.loading = false;
    } catch (error) {
      throw error;
    } finally {
      this.loading = false;
    }
  }

  async createPickingTask(ocIds, warehouseAccountId) {
    try {
      this.loading = true;
      const {
        data: { createPickingTask },
      } = await this.client.mutate({
        mutation: CREATE_PICKING_TASK,
        variables: {
          ocIds,
          warehouseAccountId,
        },
      });
      this.pickingTask = createPickingTask;
      this.loading = false;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      this.loading = false;
    }
  }

  get items() {
    if (!this.cachedItems) {
      this.fetchItems();
    }

    const orgNameById = (this.organisationStore.orgNameItems || []).reduce((acc, curr) => {
      acc[curr.id] = curr.name;
      return acc;
    }, {});

    return (this.cachedItems || []).map((oc) => ({
      ...oc,
      organisationName: orgNameById[oc.organisationId],
      numOfItems: sum(oc.outboundlist_sku.map((sku) => sku.qty)),
    }));
  }

  setDuplicatedOutboundId(id) {
    if (id) {
      this.duplicatedOutboundId = id;
    }
  }

  getDuplicatedOutbound() {
    if (this.duplicatedOutboundId) {
      return this.items.find((oc) => this.duplicatedOutboundId === oc.consignment_no);
    }
    return null;
  }

  setWarehouseSelectionVisible = (visible) => {
    this.warehouseSelectionVisible = visible;
  };

  setOnWarehouseSelectionSubmit = (onSubmit) => {
    this.onWarehouseSelectionSubmit = onSubmit;
  };

  openCreatePickingWaveTaskDialogue = () => {
    this.createPickingWaveTaskModalOpen = true;
  };

  closeCreatePickingWaveTaskDialogue = () => {
    this.createPickingWaveTaskModalOpen = false;
  };

  setDraftPickingWaveTask = (draftPickingWaveTask) => {
    this.draftPickingTask = draftPickingWaveTask;
  };
}
