import * as React from 'react';
import { observer } from 'mobx-react';
import CreateShipmentForm from './CreateShipmentForm';

/**
 * React component for editing existing shipment orders
 * @param data - Existing shipment order data to edit
 * @param warehouseAccountId - ID of the warehouse account
 * @param onSuccess - Callback function called after successful update
 * @returns Shipment order edit form
 */
const EditShipmentForm = observer(({ data, warehouseAccountId, onSuccess }) => {
  return (
    <CreateShipmentForm
      data={data}
      warehouseAccountId={warehouseAccountId}
      onSuccess={onSuccess}
      isEdit={true}
    />
  );
});

export default EditShipmentForm;
