import {
  CARRIER_ALLOW_PRINT_MANIFESTS,
  SHIPMENT_ORDER_STATES,
  SHIPMENT_ORDER_STATE_MAP,
} from '@ezom/library/lib/cjs/constants';
import { weightGramToKg } from '@ezom/library/lib/cjs/utils';
import { Layout } from '@ui-kitten/components';
import { observer } from 'mobx-react';
import { all } from 'ramda';
import React, { useEffect, useMemo, useState } from 'react';
import { Dimensions, ScrollView, StyleSheet } from 'react-native';
import { Avatar, Card, Divider, FAB, Text } from 'react-native-paper';
import Background from 'src/components/Background';
import BatchTrackingShipmentResultModal from '../../components/CourierShipments/BatchTrackingShipmentResultModal';
import RowDetail from '../../components/RowDetail';
import Table from '../../components/Table';
import TransactionDetail from '../../components/TransactionDetail';
import { UIStatusWrapper } from '../../components/ui-status';
import { useIsMobile } from '../../core/responsive.utils';
import { paperNativeTheme } from '../../core/theme';
import { printBase64Pdf, sanitize, unifiedAlert, unifiedConfirm } from '../../core/utils/utils';
import { courierShipmentStore } from '../../store';

const addressFormatter = (prefix) => (_, shipmentOrder) => {
  if (!shipmentOrder) {
    return null;
  }
  return (
    <Layout>
      <Text category="p1" style={styles.cellText}>
        {' '}
        {sanitize(shipmentOrder[`${prefix}_name`])} {sanitize(shipmentOrder[`${prefix}_company`])}
      </Text>
      <Text category="p2" style={styles.cellText}>
        {sanitize(shipmentOrder[`${prefix}_house_number`])}{' '}
        {sanitize(shipmentOrder[`${prefix}_street`])}
      </Text>
      <Text category="p2">
        {sanitize(shipmentOrder[`${prefix}_district`])} {sanitize(shipmentOrder[`${prefix}_city`])}{' '}
        {sanitize(shipmentOrder[`${prefix}_state`])}{' '}
        {sanitize(shipmentOrder[`${prefix}_post_code`])}{' '}
        {sanitize(shipmentOrder[`${prefix}_country`])}
      </Text>
      <Text category="p2">
        {sanitize(shipmentOrder[`${prefix}_email`])} {sanitize(shipmentOrder[`${prefix}_phone`])}
      </Text>
    </Layout>
  );
};

const commonInfos = [
  {
    label: 'ID',
    key: 'id',
  },
  {
    label: 'Reference No.',
    key: 'ref_no',
  },
  {
    label: 'Sales No.',
    key: 'sales_no',
  },
  {
    label: 'Status',
    key: 'status',
    format: (status) => SHIPMENT_ORDER_STATE_MAP[status] || status,
  },
  {
    label: 'Total Weight',
    key: 'total_weight',
    format: weightGramToKg(true),
  },
  {
    label: 'Total Volume',
    key: 'total_volume',
    format: (v) => `${v}㎥`,
  },
  {
    label: 'Create time',
    key: 'create_time',
    format: (number) => new Date(Number(number)).toLocaleString(),
  },
  {
    label: 'Update time',
    key: 'update_time',
    format: (number) => new Date(Number(number)).toLocaleString(),
  },
  {
    label: 'Note',
    key: 'note',
  },
  {
    label: 'Error',
    key: 'error',
  },
];

const senderInfo = [
  {
    label: 'Pickup address',
    key: 'pickup_street',
    format: addressFormatter('pickup'),
  },
];

const recipientInfo = [
  {
    label: 'Receiver address',
    key: 'receiver_street',
    format: addressFormatter('receiver'),
  },
];

const windowHeight = Dimensions.get('window').height;

const ShipmentOrderDetail = observer(({ navigation, route }) => {
  const decodedId = (route?.params?.id && decodeURIComponent(route?.params?.id)) || null;
  const warehouseAccountId =
    (route?.params?.warehouseAccountId && decodeURIComponent(route?.params?.warehouseAccountId)) ||
    null;
  const [detail, setDetail] = useState(null);
  if (route.params.id !== decodedId) {
    navigation.setParams({ id: decodedId });
    return null;
  }

  const { id } = route.params || {};
  useEffect(() => {
    (async () => {
      const shipmentOrder = await courierShipmentStore.getShipmentOrder(warehouseAccountId, id);
      setDetail(shipmentOrder);
    })();
  }, [id]);

  const isMobile = useIsMobile();

  const [fabOpen, setFabOpen] = useState(false);

  const fabActions = useMemo(() => {
    const actions = [];

    if ([SHIPMENT_ORDER_STATES.Draft, SHIPMENT_ORDER_STATES.Submitted].includes(detail?.status)) {
      actions.push({
        icon: 'printer',
        label: 'Print laberls',
        onPress: async () => {
          try {
            const labels = await courierShipmentStore.printLabels(warehouseAccountId, [detail.id]);
            for (let label of labels) {
              printBase64Pdf(label);
            }
          } catch (e) {
            unifiedAlert(e);
          }
        },
      });

      if (
        detail?.status === SHIPMENT_ORDER_STATES.Submitted &&
        all(
          (s) => CARRIER_ALLOW_PRINT_MANIFESTS.includes(s.logistics_provider),
          detail?.shipments || [],
        )
      ) {
        actions.push({
          icon: 'printer',
          label: 'Print manifests',
          onPress: async () => {
            try {
              const labels = await courierShipmentStore.printManifests(warehouseAccountId, [
                detail.id,
              ]);
              for (let label of labels) {
                printBase64Pdf(label);
              }
            } catch (e) {
              unifiedAlert(e);
            }
          },
        });
      }
    }
    if ([SHIPMENT_ORDER_STATES.Draft].includes(detail?.status)) {
      actions.push({
        icon: 'cancel',
        label: 'Cancel',
        onPress: async () => {
          const id = detail.id;
          unifiedConfirm(
            'Confirmation',
            'Are you sure you want to cancel this shipment?',
            async () => {
              try {
                await courierShipmentStore.cancelItem(warehouseAccountId, id);
                const shipmentOrder = await courierShipmentStore.getShipmentOrder(
                  warehouseAccountId,
                  id,
                );
                setDetail(shipmentOrder);
              } catch (e) {
                unifiedAlert(e);
              }
            },
          );
        },
      });
    }

    if (SHIPMENT_ORDER_STATES.Draft === detail?.status) {
      actions.push({
        icon: 'truck-check',
        label: 'Submit',
        onPress: async () => {
          unifiedConfirm(
            'Are you sure you want to submit this shipment?',
            'Shipment cannot be cancelled after submission.',
            async () => {
              try {
                const id = detail.id;
                const [submittedItem] = await courierShipmentStore.submitItems(warehouseAccountId, [
                  id,
                ]);
                const { createTransaction } = submittedItem;
                const shipmentOrder = await courierShipmentStore.getShipmentOrder(
                  warehouseAccountId,
                  id,
                );
                setDetail(shipmentOrder);
                unifiedAlert(
                  `Shipment ${shipmentOrder.id} submitted.\n Cost is $${createTransaction.amount}.`,
                );
              } catch (e) {
                unifiedAlert(e);
              }
            },
          );
        },
      });
    }

    if (
      [
        SHIPMENT_ORDER_STATES.Submitted,
        SHIPMENT_ORDER_STATES.Dispatched,
        SHIPMENT_ORDER_STATES.Draft,
      ].includes(detail?.status)
    ) {
      actions.push({
        icon: 'radar',
        label: 'Track shipment(s)',
        onPress: async () => {
          try {
            const id = detail.id;
            const trackingInfo = await courierShipmentStore.trackShipmentOrder(warehouseAccountId, [
              id,
            ]);
            setTrackingShipmentModal({ data: trackingInfo, isOpen: true });
          } catch (e) {
            unifiedAlert(e);
          }
        },
      });
    }
    return actions;
  }, [detail]);

  const [trackingShipmentModal, setTrackingShipmentModal] = useState({
    data: null,
    isOpen: false,
  });

  return (
    <Background fullWidth={true}>
      <BatchTrackingShipmentResultModal
        data={trackingShipmentModal.data}
        onClose={() => {
          setTrackingShipmentModal({
            data: null,
            isOpen: false,
          });
        }}
        visible={trackingShipmentModal.isOpen}
      />
      <UIStatusWrapper
        status={{
          indeterminate: !detail,
        }}>
        <ScrollView
          style={[styles.container, isMobile ? styles.mobileContainer : styles.desktopContainer]}>
          <Card style={[styles.card, isMobile ? styles.mobileCard : styles.desktopCard]}>
            <Card.Title
              title="Common Info"
              left={(props) => <Avatar.Icon {...props} icon="information-outline" />}
            />
            <Divider />
            <Card.Content style={styles.content}>
              <Layout style={isMobile ? styles.mobileColumn : styles.desktopColumn}>
                {commonInfos
                  .slice(0, Math.ceil(commonInfos.length / 2))
                  .map(({ key, label, format }, index) => (
                    <RowDetail
                      key={`${key}-${index}`}
                      label={label}
                      value={detail && detail[key]}
                      rowValue={detail}
                      format={format}
                    />
                  ))}
              </Layout>
              <Layout style={isMobile ? styles.mobileColumn : styles.desktopColumn}>
                {commonInfos
                  .slice(Math.ceil(commonInfos.length / 2))
                  .map(({ key, label, format }, index) => (
                    <RowDetail
                      key={`${key}-${index}`}
                      label={label}
                      value={detail && detail[key]}
                      format={format}
                    />
                  ))}
              </Layout>
            </Card.Content>
          </Card>

          <Card style={[styles.card, isMobile ? styles.mobileCard : styles.desktopCard]}>
            <Card.Title
              title="Recipient Info"
              left={(props) => <Avatar.Icon {...props} icon={'account'} />}
            />
            <Divider />
            <Card.Content style={styles.content}>
              <Layout style={isMobile ? styles.mobileColumn : styles.desktopColumn}>
                {recipientInfo.map(({ key, label, format }, index) => (
                  <RowDetail
                    key={`${key}-${index}`}
                    label={label}
                    value={(detail && format && format(detail?.[key], detail)) || detail?.[key]}
                  />
                ))}
              </Layout>
            </Card.Content>
          </Card>

          <Card style={[styles.card, isMobile ? styles.mobileCard : styles.desktopCard]}>
            <Card.Title
              title="Pickup Info"
              left={(props) => <Avatar.Icon {...props} icon={'account'} />}
            />
            <Divider />
            <Card.Content style={styles.content}>
              <Layout style={isMobile ? styles.mobileColumn : styles.desktopColumn}>
                {senderInfo.map(({ key, label, format }, index) => (
                  <RowDetail
                    key={`${key}-${index}`}
                    label={label}
                    value={(detail && format && format(detail?.[key], detail)) || detail?.[key]}
                  />
                ))}
              </Layout>
            </Card.Content>
          </Card>

          <Card style={[styles.card, isMobile ? styles.mobileCard : styles.desktopCard]}>
            <Card.Title
              title="Shipment List"
              left={(props) => <Avatar.Icon {...props} icon={'table'} />}
            />
            <Divider />
            <Card.Content>
              <Table
                items={detail?.shipments}
                displayKeys={['courierConsignmentId', 'tracking_number', 'courier', 'items']}
                titleByKey={{
                  courierConsignmentId: 'Shipment ID',
                  tracking_number: 'Tracking No.',
                  courier: 'courier',
                  items: 'Items',
                }}
                formatterByKey={{
                  items: (items) =>
                    items.map((item, index) => (
                      <Layout
                        key={`item-${index}`}
                        style={{ display: 'flex', flexDirection: 'column' }}>
                        <Text category="p2" style={styles.cellText}>
                          {item.qty}&nbsp;x&nbsp;{weightGramToKg(true)(item.weight)} {item.length}
                          cm*
                          {item.width}cm*
                          {item.height}cm {item.sku}
                        </Text>
                      </Layout>
                    )),
                }}
              />
            </Card.Content>
          </Card>
          {detail?.transactions && detail.transactions.length > 0 ? (
            detail.transactions.map((transaction, index) => (
              <TransactionDetail key={transaction.id || index} transaction={transaction} />
            ))
          ) : (
            <TransactionDetail transaction={{}} />
          )}
        </ScrollView>
        {fabActions.length > 0 ? (
          <FAB.Group
            visible={true}
            isFabVisible={true}
            fabStyle={{ backgroundColor: paperNativeTheme.colors.primary }}
            open={fabOpen}
            icon={fabOpen ? 'chevron-down' : 'chevron-up'}
            actions={fabActions}
            onStateChange={({ open }) => setFabOpen(open)}
          />
        ) : null}
      </UIStatusWrapper>
    </Background>
  );
});

const styles = StyleSheet.create({
  card: {
    marginHorizontal: '1em',
    marginVertical: '1em',
  },

  desktopCard: {
    marginHorizontal: '0.8em',
  },

  mobileCard: {
    marginHorizontal: '1em',
    marginBottom: '1em',
  },

  desktopColumn: {
    width: '50%',
    flex: 1,
    flexDirection: 'column',
  },

  mobileColumn: {
    width: '100%',
  },

  content: {
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },

  container: {
    overflowY: 'auto',
  },

  mobileContainer: {
    height: 0.82 * windowHeight,
  },

  contentContainer: {
    paddingHorizontal: 8,
    paddingVertical: 4,
  },

  item: {
    marginVertical: 4,
  },

  desktopContainer: {
    height: 0.9 * windowHeight,
  },

  cellText: { marginBottom: '0.1em' },
});
export default ShipmentOrderDetail;
